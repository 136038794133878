내보내기 const 이모티콘 코드포인트: 레코드<string, string=""> = {
    "2049": "2049-fe0f",
    "2122": "2122-fe0f",
    "2139": "2139-fe0f",
    "2194": "2194-fe0f",
    "2195": "2195-fe0f",
    "2196": "2196-fe0f",
    "2197": "2197-fe0f",
    "2198": "2198-fe0f",
    "2199": "2199-fe0f",
    "2328": "2328-fe0f",
    "2600": "2600-fe0f",
    "2601": "2601-fe0f",
    "2602": "2602-fe0f",
    "2603": "2603-fe0f",
    "2604": "2604-fe0f",
    "2611": "2611-fe0f",
    "2618": "2618-fe0f",
    "2620": "2620-fe0f",
    "2622": "2622-fe0f",
    "2623": "2623-fe0f",
    "2626": "2626-fe0f",
    "2638": "2638-fe0f",
    "2639": "2639-fe0f",
    "2640": "2640-fe0f",
    "2642": "2642-fe0f",
    "2660": "2660-fe0f",
    "2663": "2663-fe0f",
    "2665": "2665-fe0f",
    "2666": "2666-fe0f",
    "2668": "2668-fe0f",
    "2692": "2692-fe0f",
    "2694": "2694-fe0f",
    "2695": "2695-fe0f",
    "2696": "2696-fe0f",
    "2697": "2697-fe0f",
    "2699": "2699-fe0f",
    "2702": "2702-fe0f",
    "2708": "2708-fe0f",
    "2709": "2709-fe0f",
    "2712": "2712-fe0f",
    "2714": "2714-fe0f",
    "2716": "2716-fe0f",
    "2721": "2721-fe0f",
    "2733": "2733-fe0f",
    "2734": "2734-fe0f",
    "2744": "2744-fe0f",
    "2747": "2747-fe0f",
    "2763": "2763-fe0f",
    "2764": "2764-fe0f",
    "2934": "2934-fe0f",
    "2935": "2935-fe0f",
    "3030": "3030-fe0f",
    "3297": "3297-fe0f",
    "3299": "3299-fe0f",
    "263a": "263a-fe0f",
    "1f636-1f32b": "1f636-200d-1f32b-fe0f",
    "1f62e-1f4a8": "1f62e-200d-1f4a8",
    "1f635-1f4ab": "1f635-200d-1f4ab",
    "1faf1-1f3fb-1faf2-1f3fc": "1faf1-1f3fb-200d-1faf2-1f3fc",
    "1faf1-1f3fb-1faf2-1f3fd": "1faf1-1f3fb-200d-1faf2-1f3fd",
    "1faf1-1f3fb-1faf2-1f3fe": "1faf1-1f3fb-200d-1faf2-1f3fe",
    "1faf1-1f3fb-1faf2-1f3ff": "1faf1-1f3fb-200d-1faf2-1f3ff",
    "1faf1-1f3fc-1faf2-1f3fb": "1faf1-1f3fc-200d-1faf2-1f3fb",
    "1faf1-1f3fc-1faf2-1f3fd": "1faf1-1f3fc-200d-1faf2-1f3fd",
    "1faf1-1f3fc-1faf2-1f3fe": "1faf1-1f3fc-200d-1faf2-1f3fe",
    "1faf1-1f3fc-1faf2-1f3ff": "1faf1-1f3fc-200d-1faf2-1f3ff",
    "1faf1-1f3fd-1faf2-1f3fb": "1faf1-1f3fd-200d-1faf2-1f3fb",
    "1faf1-1f3fd-1faf2-1f3fc": "1faf1-1f3fd-200d-1faf2-1f3fc",
    "1faf1-1f3fd-1faf2-1f3fe": "1faf1-1f3fd-200d-1faf2-1f3fe",
    "1faf1-1f3fd-1faf2-1f3ff": "1faf1-1f3fd-200d-1faf2-1f3ff",
    "1faf1-1f3fe-1faf2-1f3fb": "1faf1-1f3fe-200d-1faf2-1f3fb",
    "1faf1-1f3fe-1faf2-1f3fc": "1faf1-1f3fe-200d-1faf2-1f3fc",
    "1faf1-1f3fe-1faf2-1f3fd": "1faf1-1f3fe-200d-1faf2-1f3fd",
    "1faf1-1f3fe-1faf2-1f3ff": "1faf1-1f3fe-200d-1faf2-1f3ff",
    "1faf1-1f3ff-1faf2-1f3fb": "1faf1-1f3ff-200d-1faf2-1f3fb",
    "1faf1-1f3ff-1faf2-1f3fc": "1faf1-1f3ff-200d-1faf2-1f3fc",
    "1faf1-1f3ff-1faf2-1f3fd": "1faf1-1f3ff-200d-1faf2-1f3fd",
    "1faf1-1f3ff-1faf2-1f3fe": "1faf1-1f3ff-200d-1faf2-1f3fe",
    "270c": "270c-fe0f",
    "261d": "261d-fe0f",
    "1f590": "1f590-fe0f",
    "270d": "270d-fe0f",
    "1f441": "1f441-fe0f",
    "1f5e3": "1f5e3-fe0f",
    "1f9d1-1f9b1": "1f9d1-200d-1f9b1",
    "1f9d1-1f3fb-1f9b1": "1f9d1-1f3fb-200d-1f9b1",
    "1f9d1-1f3fc-1f9b1": "1f9d1-1f3fc-200d-1f9b1",
    "1f9d1-1f3fd-1f9b1": "1f9d1-1f3fd-200d-1f9b1",
    "1f9d1-1f3fe-1f9b1": "1f9d1-1f3fe-200d-1f9b1",
    "1f9d1-1f3ff-1f9b1": "1f9d1-1f3ff-200d-1f9b1",
    "1f469-1f9b1": "1f469-200d-1f9b1",
    "1f469-1f3fb-1f9b1": "1f469-1f3fb-200d-1f9b1",
    "1f469-1f3fc-1f9b1": "1f469-1f3fc-200d-1f9b1",
    "1f469-1f3fd-1f9b1": "1f469-1f3fd-200d-1f9b1",
    "1f469-1f3fe-1f9b1": "1f469-1f3fe-200d-1f9b1",
    "1f469-1f3ff-1f9b1": "1f469-1f3ff-200d-1f9b1",
    "1f468-1f9b1": "1f468-200d-1f9b1",
    "1f468-1f3fb-1f9b1": "1f468-1f3fb-200d-1f9b1",
    "1f468-1f3fc-1f9b1": "1f468-1f3fc-200d-1f9b1",
    "1f468-1f3fd-1f9b1": "1f468-1f3fd-200d-1f9b1",
    "1f468-1f3fe-1f9b1": "1f468-1f3fe-200d-1f9b1",
    "1f468-1f3ff-1f9b1": "1f468-1f3ff-200d-1f9b1",
    "1f9d1-1f9b0": "1f9d1-200d-1f9b0",
    "1f9d1-1f3fb-1f9b0": "1f9d1-1f3fb-200d-1f9b0",
    "1f9d1-1f3fc-1f9b0": "1f9d1-1f3fc-200d-1f9b0",
    "1f9d1-1f3fd-1f9b0": "1f9d1-1f3fd-200d-1f9b0",
    "1f9d1-1f3fe-1f9b0": "1f9d1-1f3fe-200d-1f9b0",
    "1f9d1-1f3ff-1f9b0": "1f9d1-1f3ff-200d-1f9b0",
    "1f469-1f9b0": "1f469-200d-1f9b0",
    "1f469-1f3fb-1f9b0": "1f469-1f3fb-200d-1f9b0",
    "1f469-1f3fc-1f9b0": "1f469-1f3fc-200d-1f9b0",
    "1f469-1f3fd-1f9b0": "1f469-1f3fd-200d-1f9b0",
    "1f469-1f3fe-1f9b0": "1f469-1f3fe-200d-1f9b0",
    "1f469-1f3ff-1f9b0": "1f469-1f3ff-200d-1f9b0",
    "1f468-1f9b0": "1f468-200d-1f9b0",
    "1f468-1f3fb-1f9b0": "1f468-1f3fb-200d-1f9b0",
    "1f468-1f3fc-1f9b0": "1f468-1f3fc-200d-1f9b0",
    "1f468-1f3fd-1f9b0": "1f468-1f3fd-200d-1f9b0",
    "1f468-1f3fe-1f9b0": "1f468-1f3fe-200d-1f9b0",
    "1f468-1f3ff-1f9b0": "1f468-1f3ff-200d-1f9b0",
    "1f471-2640": "1f471-200d-2640-fe0f",
    "1f471-1f3fb-2640": "1f471-1f3fb-200d-2640-fe0f",
    "1f471-1f3fc-2640": "1f471-1f3fc-200d-2640-fe0f",
    "1f471-1f3fd-2640": "1f471-1f3fd-200d-2640-fe0f",
    "1f471-1f3fe-2640": "1f471-1f3fe-200d-2640-fe0f",
    "1f471-1f3ff-2640": "1f471-1f3ff-200d-2640-fe0f",
    "1f471-2642": "1f471-200d-2642-fe0f",
    "1f471-1f3fb-2642": "1f471-1f3fb-200d-2642-fe0f",
    "1f471-1f3fc-2642": "1f471-1f3fc-200d-2642-fe0f",
    "1f471-1f3fd-2642": "1f471-1f3fd-200d-2642-fe0f",
    "1f471-1f3fe-2642": "1f471-1f3fe-200d-2642-fe0f",
    "1f471-1f3ff-2642": "1f471-1f3ff-200d-2642-fe0f",
    "1f9d1-1f9b3": "1f9d1-200d-1f9b3",
    "1f9d1-1f3fb-1f9b3": "1f9d1-1f3fb-200d-1f9b3",
    "1f9d1-1f3fc-1f9b3": "1f9d1-1f3fc-200d-1f9b3",
    "1f9d1-1f3fd-1f9b3": "1f9d1-1f3fd-200d-1f9b3",
    "1f9d1-1f3fe-1f9b3": "1f9d1-1f3fe-200d-1f9b3",
    "1f9d1-1f3ff-1f9b3": "1f9d1-1f3ff-200d-1f9b3",
    "1f469-1f9b3": "1f469-200d-1f9b3",
    "1f469-1f3fb-1f9b3": "1f469-1f3fb-200d-1f9b3",
    "1f469-1f3fc-1f9b3": "1f469-1f3fc-200d-1f9b3",
    "1f469-1f3fd-1f9b3": "1f469-1f3fd-200d-1f9b3",
    "1f469-1f3fe-1f9b3": "1f469-1f3fe-200d-1f9b3",
    "1f469-1f3ff-1f9b3": "1f469-1f3ff-200d-1f9b3",
    "1f468-1f9b3": "1f468-200d-1f9b3",
    "1f468-1f3fb-1f9b3": "1f468-1f3fb-200d-1f9b3",
    "1f468-1f3fc-1f9b3": "1f468-1f3fc-200d-1f9b3",
    "1f468-1f3fd-1f9b3": "1f468-1f3fd-200d-1f9b3",
    "1f468-1f3fe-1f9b3": "1f468-1f3fe-200d-1f9b3",
    "1f468-1f3ff-1f9b3": "1f468-1f3ff-200d-1f9b3",
    "1f9d1-1f9b2": "1f9d1-200d-1f9b2",
    "1f9d1-1f3fb-1f9b2": "1f9d1-1f3fb-200d-1f9b2",
    "1f9d1-1f3fc-1f9b2": "1f9d1-1f3fc-200d-1f9b2",
    "1f9d1-1f3fd-1f9b2": "1f9d1-1f3fd-200d-1f9b2",
    "1f9d1-1f3fe-1f9b2": "1f9d1-1f3fe-200d-1f9b2",
    "1f9d1-1f3ff-1f9b2": "1f9d1-1f3ff-200d-1f9b2",
    "1f469-1f9b2": "1f469-200d-1f9b2",
    "1f469-1f3fb-1f9b2": "1f469-1f3fb-200d-1f9b2",
    "1f469-1f3fc-1f9b2": "1f469-1f3fc-200d-1f9b2",
    "1f469-1f3fd-1f9b2": "1f469-1f3fd-200d-1f9b2",
    "1f469-1f3fe-1f9b2": "1f469-1f3fe-200d-1f9b2",
    "1f469-1f3ff-1f9b2": "1f469-1f3ff-200d-1f9b2",
    "1f468-1f9b2": "1f468-200d-1f9b2",
    "1f468-1f3fb-1f9b2": "1f468-1f3fb-200d-1f9b2",
    "1f468-1f3fc-1f9b2": "1f468-1f3fc-200d-1f9b2",
    "1f468-1f3fd-1f9b2": "1f468-1f3fd-200d-1f9b2",
    "1f468-1f3fe-1f9b2": "1f468-1f3fe-200d-1f9b2",
    "1f468-1f3ff-1f9b2": "1f468-1f3ff-200d-1f9b2",
    "1f9d4-2640": "1f9d4-200d-2640-fe0f",
    "1f9d4-1f3fb-2640": "1f9d4-1f3fb-200d-2640-fe0f",
    "1f9d4-1f3fc-2640": "1f9d4-1f3fc-200d-2640-fe0f",
    "1f9d4-1f3fd-2640": "1f9d4-1f3fd-200d-2640-fe0f",
    "1f9d4-1f3fe-2640": "1f9d4-1f3fe-200d-2640-fe0f",
    "1f9d4-1f3ff-2640": "1f9d4-1f3ff-200d-2640-fe0f",
    "1f9d4-2642": "1f9d4-200d-2642-fe0f",
    "1f9d4-1f3fb-2642": "1f9d4-1f3fb-200d-2642-fe0f",
    "1f9d4-1f3fc-2642": "1f9d4-1f3fc-200d-2642-fe0f",
    "1f9d4-1f3fd-2642": "1f9d4-1f3fd-200d-2642-fe0f",
    "1f9d4-1f3fe-2642": "1f9d4-1f3fe-200d-2642-fe0f",
    "1f9d4-1f3ff-2642": "1f9d4-1f3ff-200d-2642-fe0f",
    "1f473-2640": "1f473-200d-2640-fe0f",
    "1f473-1f3fb-2640": "1f473-1f3fb-200d-2640-fe0f",
    "1f473-1f3fc-2640": "1f473-1f3fc-200d-2640-fe0f",
    "1f473-1f3fd-2640": "1f473-1f3fd-200d-2640-fe0f",
    "1f473-1f3fe-2640": "1f473-1f3fe-200d-2640-fe0f",
    "1f473-1f3ff-2640": "1f473-1f3ff-200d-2640-fe0f",
    "1f473-2642": "1f473-200d-2642-fe0f",
    "1f473-1f3fb-2642": "1f473-1f3fb-200d-2642-fe0f",
    "1f473-1f3fc-2642": "1f473-1f3fc-200d-2642-fe0f",
    "1f473-1f3fd-2642": "1f473-1f3fd-200d-2642-fe0f",
    "1f473-1f3fe-2642": "1f473-1f3fe-200d-2642-fe0f",
    "1f473-1f3ff-2642": "1f473-1f3ff-200d-2642-fe0f",
    "1f46e-2640": "1f46e-200d-2640-fe0f",
    "1f46e-1f3fb-2640": "1f46e-1f3fb-200d-2640-fe0f",
    "1f46e-1f3fc-2640": "1f46e-1f3fc-200d-2640-fe0f",
    "1f46e-1f3fd-2640": "1f46e-1f3fd-200d-2640-fe0f",
    "1f46e-1f3fe-2640": "1f46e-1f3fe-200d-2640-fe0f",
    "1f46e-1f3ff-2640": "1f46e-1f3ff-200d-2640-fe0f",
    "1f46e-2642": "1f46e-200d-2642-fe0f",
    "1f46e-1f3fb-2642": "1f46e-1f3fb-200d-2642-fe0f",
    "1f46e-1f3fc-2642": "1f46e-1f3fc-200d-2642-fe0f",
    "1f46e-1f3fd-2642": "1f46e-1f3fd-200d-2642-fe0f",
    "1f46e-1f3fe-2642": "1f46e-1f3fe-200d-2642-fe0f",
    "1f46e-1f3ff-2642": "1f46e-1f3ff-200d-2642-fe0f",
    "1f477-2640": "1f477-200d-2640-fe0f",
    "1f477-1f3fb-2640": "1f477-1f3fb-200d-2640-fe0f",
    "1f477-1f3fc-2640": "1f477-1f3fc-200d-2640-fe0f",
    "1f477-1f3fd-2640": "1f477-1f3fd-200d-2640-fe0f",
    "1f477-1f3fe-2640": "1f477-1f3fe-200d-2640-fe0f",
    "1f477-1f3ff-2640": "1f477-1f3ff-200d-2640-fe0f",
    "1f477-2642": "1f477-200d-2642-fe0f",
    "1f477-1f3fb-2642": "1f477-1f3fb-200d-2642-fe0f",
    "1f477-1f3fc-2642": "1f477-1f3fc-200d-2642-fe0f",
    "1f477-1f3fd-2642": "1f477-1f3fd-200d-2642-fe0f",
    "1f477-1f3fe-2642": "1f477-1f3fe-200d-2642-fe0f",
    "1f477-1f3ff-2642": "1f477-1f3ff-200d-2642-fe0f",
    "1f482-2640": "1f482-200d-2640-fe0f",
    "1f482-1f3fb-2640": "1f482-1f3fb-200d-2640-fe0f",
    "1f482-1f3fc-2640": "1f482-1f3fc-200d-2640-fe0f",
    "1f482-1f3fd-2640": "1f482-1f3fd-200d-2640-fe0f",
    "1f482-1f3fe-2640": "1f482-1f3fe-200d-2640-fe0f",
    "1f482-1f3ff-2640": "1f482-1f3ff-200d-2640-fe0f",
    "1f482-2642": "1f482-200d-2642-fe0f",
    "1f482-1f3fb-2642": "1f482-1f3fb-200d-2642-fe0f",
    "1f482-1f3fc-2642": "1f482-1f3fc-200d-2642-fe0f",
    "1f482-1f3fd-2642": "1f482-1f3fd-200d-2642-fe0f",
    "1f482-1f3fe-2642": "1f482-1f3fe-200d-2642-fe0f",
    "1f482-1f3ff-2642": "1f482-1f3ff-200d-2642-fe0f",
    "1f575": "1f575-fe0f",
    "1f575-2640": "1f575-fe0f-200d-2640-fe0f",
    "1f575-1f3fb-2640": "1f575-1f3fb-200d-2640-fe0f",
    "1f575-1f3fc-2640": "1f575-1f3fc-200d-2640-fe0f",
    "1f575-1f3fd-2640": "1f575-1f3fd-200d-2640-fe0f",
    "1f575-1f3fe-2640": "1f575-1f3fe-200d-2640-fe0f",
    "1f575-1f3ff-2640": "1f575-1f3ff-200d-2640-fe0f",
    "1f575-2642": "1f575-fe0f-200d-2642-fe0f",
    "1f575-1f3fb-2642": "1f575-1f3fb-200d-2642-fe0f",
    "1f575-1f3fc-2642": "1f575-1f3fc-200d-2642-fe0f",
    "1f575-1f3fd-2642": "1f575-1f3fd-200d-2642-fe0f",
    "1f575-1f3fe-2642": "1f575-1f3fe-200d-2642-fe0f",
    "1f575-1f3ff-2642": "1f575-1f3ff-200d-2642-fe0f",
    "1f9d1-2695": "1f9d1-200d-2695-fe0f",
    "1f9d1-1f3fb-2695": "1f9d1-1f3fb-200d-2695-fe0f",
    "1f9d1-1f3fc-2695": "1f9d1-1f3fc-200d-2695-fe0f",
    "1f9d1-1f3fd-2695": "1f9d1-1f3fd-200d-2695-fe0f",
    "1f9d1-1f3fe-2695": "1f9d1-1f3fe-200d-2695-fe0f",
    "1f9d1-1f3ff-2695": "1f9d1-1f3ff-200d-2695-fe0f",
    "1f469-2695": "1f469-200d-2695-fe0f",
    "1f469-1f3fb-2695": "1f469-1f3fb-200d-2695-fe0f",
    "1f469-1f3fc-2695": "1f469-1f3fc-200d-2695-fe0f",
    "1f469-1f3fd-2695": "1f469-1f3fd-200d-2695-fe0f",
    "1f469-1f3fe-2695": "1f469-1f3fe-200d-2695-fe0f",
    "1f469-1f3ff-2695": "1f469-1f3ff-200d-2695-fe0f",
    "1f468-2695": "1f468-200d-2695-fe0f",
    "1f468-1f3fb-2695": "1f468-1f3fb-200d-2695-fe0f",
    "1f468-1f3fc-2695": "1f468-1f3fc-200d-2695-fe0f",
    "1f468-1f3fd-2695": "1f468-1f3fd-200d-2695-fe0f",
    "1f468-1f3fe-2695": "1f468-1f3fe-200d-2695-fe0f",
    "1f468-1f3ff-2695": "1f468-1f3ff-200d-2695-fe0f",
    "1f9d1-1f33e": "1f9d1-200d-1f33e",
    "1f9d1-1f3fb-1f33e": "1f9d1-1f3fb-200d-1f33e",
    "1f9d1-1f3fc-1f33e": "1f9d1-1f3fc-200d-1f33e",
    "1f9d1-1f3fd-1f33e": "1f9d1-1f3fd-200d-1f33e",
    "1f9d1-1f3fe-1f33e": "1f9d1-1f3fe-200d-1f33e",
    "1f9d1-1f3ff-1f33e": "1f9d1-1f3ff-200d-1f33e",
    "1f469-1f33e": "1f469-200d-1f33e",
    "1f469-1f3fb-1f33e": "1f469-1f3fb-200d-1f33e",
    "1f469-1f3fc-1f33e": "1f469-1f3fc-200d-1f33e",
    "1f469-1f3fd-1f33e": "1f469-1f3fd-200d-1f33e",
    "1f469-1f3fe-1f33e": "1f469-1f3fe-200d-1f33e",
    "1f469-1f3ff-1f33e": "1f469-1f3ff-200d-1f33e",
    "1f468-1f33e": "1f468-200d-1f33e",
    "1f468-1f3fb-1f33e": "1f468-1f3fb-200d-1f33e",
    "1f468-1f3fc-1f33e": "1f468-1f3fc-200d-1f33e",
    "1f468-1f3fd-1f33e": "1f468-1f3fd-200d-1f33e",
    "1f468-1f3fe-1f33e": "1f468-1f3fe-200d-1f33e",
    "1f468-1f3ff-1f33e": "1f468-1f3ff-200d-1f33e",
    "1f9d1-1f373": "1f9d1-200d-1f373",
    "1f9d1-1f3fb-1f373": "1f9d1-1f3fb-200d-1f373",
    "1f9d1-1f3fc-1f373": "1f9d1-1f3fc-200d-1f373",
    "1f9d1-1f3fd-1f373": "1f9d1-1f3fd-200d-1f373",
    "1f9d1-1f3fe-1f373": "1f9d1-1f3fe-200d-1f373",
    "1f9d1-1f3ff-1f373": "1f9d1-1f3ff-200d-1f373",
    "1f469-1f373": "1f469-200d-1f373",
    "1f469-1f3fb-1f373": "1f469-1f3fb-200d-1f373",
    "1f469-1f3fc-1f373": "1f469-1f3fc-200d-1f373",
    "1f469-1f3fd-1f373": "1f469-1f3fd-200d-1f373",
    "1f469-1f3fe-1f373": "1f469-1f3fe-200d-1f373",
    "1f469-1f3ff-1f373": "1f469-1f3ff-200d-1f373",
    "1f468-1f373": "1f468-200d-1f373",
    "1f468-1f3fb-1f373": "1f468-1f3fb-200d-1f373",
    "1f468-1f3fc-1f373": "1f468-1f3fc-200d-1f373",
    "1f468-1f3fd-1f373": "1f468-1f3fd-200d-1f373",
    "1f468-1f3fe-1f373": "1f468-1f3fe-200d-1f373",
    "1f468-1f3ff-1f373": "1f468-1f3ff-200d-1f373",
    "1f9d1-1f393": "1f9d1-200d-1f393",
    "1f9d1-1f3fb-1f393": "1f9d1-1f3fb-200d-1f393",
    "1f9d1-1f3fc-1f393": "1f9d1-1f3fc-200d-1f393",
    "1f9d1-1f3fd-1f393": "1f9d1-1f3fd-200d-1f393",
    "1f9d1-1f3fe-1f393": "1f9d1-1f3fe-200d-1f393",
    "1f9d1-1f3ff-1f393": "1f9d1-1f3ff-200d-1f393",
    "1f469-1f393": "1f469-200d-1f393",
    "1f469-1f3fb-1f393": "1f469-1f3fb-200d-1f393",
    "1f469-1f3fc-1f393": "1f469-1f3fc-200d-1f393",
    "1f469-1f3fd-1f393": "1f469-1f3fd-200d-1f393",
    "1f469-1f3fe-1f393": "1f469-1f3fe-200d-1f393",
    "1f469-1f3ff-1f393": "1f469-1f3ff-200d-1f393",
    "1f468-1f393": "1f468-200d-1f393",
    "1f468-1f3fb-1f393": "1f468-1f3fb-200d-1f393",
    "1f468-1f3fc-1f393": "1f468-1f3fc-200d-1f393",
    "1f468-1f3fd-1f393": "1f468-1f3fd-200d-1f393",
    "1f468-1f3fe-1f393": "1f468-1f3fe-200d-1f393",
    "1f468-1f3ff-1f393": "1f468-1f3ff-200d-1f393",
    "1f9d1-1f3a4": "1f9d1-200d-1f3a4",
    "1f9d1-1f3fb-1f3a4": "1f9d1-1f3fb-200d-1f3a4",
    "1f9d1-1f3fc-1f3a4": "1f9d1-1f3fc-200d-1f3a4",
    "1f9d1-1f3fd-1f3a4": "1f9d1-1f3fd-200d-1f3a4",
    "1f9d1-1f3fe-1f3a4": "1f9d1-1f3fe-200d-1f3a4",
    "1f9d1-1f3ff-1f3a4": "1f9d1-1f3ff-200d-1f3a4",
    "1f469-1f3a4": "1f469-200d-1f3a4",
    "1f469-1f3fb-1f3a4": "1f469-1f3fb-200d-1f3a4",
    "1f469-1f3fc-1f3a4": "1f469-1f3fc-200d-1f3a4",
    "1f469-1f3fd-1f3a4": "1f469-1f3fd-200d-1f3a4",
    "1f469-1f3fe-1f3a4": "1f469-1f3fe-200d-1f3a4",
    "1f469-1f3ff-1f3a4": "1f469-1f3ff-200d-1f3a4",
    "1f468-1f3a4": "1f468-200d-1f3a4",
    "1f468-1f3fb-1f3a4": "1f468-1f3fb-200d-1f3a4",
    "1f468-1f3fc-1f3a4": "1f468-1f3fc-200d-1f3a4",
    "1f468-1f3fd-1f3a4": "1f468-1f3fd-200d-1f3a4",
    "1f468-1f3fe-1f3a4": "1f468-1f3fe-200d-1f3a4",
    "1f468-1f3ff-1f3a4": "1f468-1f3ff-200d-1f3a4",
    "1f9d1-1f3eb": "1f9d1-200d-1f3eb",
    "1f9d1-1f3fb-1f3eb": "1f9d1-1f3fb-200d-1f3eb",
    "1f9d1-1f3fc-1f3eb": "1f9d1-1f3fc-200d-1f3eb",
    "1f9d1-1f3fd-1f3eb": "1f9d1-1f3fd-200d-1f3eb",
    "1f9d1-1f3fe-1f3eb": "1f9d1-1f3fe-200d-1f3eb",
    "1f9d1-1f3ff-1f3eb": "1f9d1-1f3ff-200d-1f3eb",
    "1f469-1f3eb": "1f469-200d-1f3eb",
    "1f469-1f3fb-1f3eb": "1f469-1f3fb-200d-1f3eb",
    "1f469-1f3fc-1f3eb": "1f469-1f3fc-200d-1f3eb",
    "1f469-1f3fd-1f3eb": "1f469-1f3fd-200d-1f3eb",
    "1f469-1f3fe-1f3eb": "1f469-1f3fe-200d-1f3eb",
    "1f469-1f3ff-1f3eb": "1f469-1f3ff-200d-1f3eb",
    "1f468-1f3eb": "1f468-200d-1f3eb",
    "1f468-1f3fb-1f3eb": "1f468-1f3fb-200d-1f3eb",
    "1f468-1f3fc-1f3eb": "1f468-1f3fc-200d-1f3eb",
    "1f468-1f3fd-1f3eb": "1f468-1f3fd-200d-1f3eb",
    "1f468-1f3fe-1f3eb": "1f468-1f3fe-200d-1f3eb",
    "1f468-1f3ff-1f3eb": "1f468-1f3ff-200d-1f3eb",
    "1f9d1-1f3ed": "1f9d1-200d-1f3ed",
    "1f9d1-1f3fb-1f3ed": "1f9d1-1f3fb-200d-1f3ed",
    "1f9d1-1f3fc-1f3ed": "1f9d1-1f3fc-200d-1f3ed",
    "1f9d1-1f3fd-1f3ed": "1f9d1-1f3fd-200d-1f3ed",
    "1f9d1-1f3fe-1f3ed": "1f9d1-1f3fe-200d-1f3ed",
    "1f9d1-1f3ff-1f3ed": "1f9d1-1f3ff-200d-1f3ed",
    "1f469-1f3ed": "1f469-200d-1f3ed",
    "1f469-1f3fb-1f3ed": "1f469-1f3fb-200d-1f3ed",
    "1f469-1f3fc-1f3ed": "1f469-1f3fc-200d-1f3ed",
    "1f469-1f3fd-1f3ed": "1f469-1f3fd-200d-1f3ed",
    "1f469-1f3fe-1f3ed": "1f469-1f3fe-200d-1f3ed",
    "1f469-1f3ff-1f3ed": "1f469-1f3ff-200d-1f3ed",
    "1f468-1f3ed": "1f468-200d-1f3ed",
    "1f468-1f3fb-1f3ed": "1f468-1f3fb-200d-1f3ed",
    "1f468-1f3fc-1f3ed": "1f468-1f3fc-200d-1f3ed",
    "1f468-1f3fd-1f3ed": "1f468-1f3fd-200d-1f3ed",
    "1f468-1f3fe-1f3ed": "1f468-1f3fe-200d-1f3ed",
    "1f468-1f3ff-1f3ed": "1f468-1f3ff-200d-1f3ed",
    "1f9d1-1f4bb": "1f9d1-200d-1f4bb",
    "1f9d1-1f3fb-1f4bb": "1f9d1-1f3fb-200d-1f4bb",
    "1f9d1-1f3fc-1f4bb": "1f9d1-1f3fc-200d-1f4bb",
    "1f9d1-1f3fd-1f4bb": "1f9d1-1f3fd-200d-1f4bb",
    "1f9d1-1f3fe-1f4bb": "1f9d1-1f3fe-200d-1f4bb",
    "1f9d1-1f3ff-1f4bb": "1f9d1-1f3ff-200d-1f4bb",
    "1f469-1f4bb": "1f469-200d-1f4bb",
    "1f469-1f3fb-1f4bb": "1f469-1f3fb-200d-1f4bb",
    "1f469-1f3fc-1f4bb": "1f469-1f3fc-200d-1f4bb",
    "1f469-1f3fd-1f4bb": "1f469-1f3fd-200d-1f4bb",
    "1f469-1f3fe-1f4bb": "1f469-1f3fe-200d-1f4bb",
    "1f469-1f3ff-1f4bb": "1f469-1f3ff-200d-1f4bb",
    "1f468-1f4bb": "1f468-200d-1f4bb",
    "1f468-1f3fb-1f4bb": "1f468-1f3fb-200d-1f4bb",
    "1f468-1f3fc-1f4bb": "1f468-1f3fc-200d-1f4bb",
    "1f468-1f3fd-1f4bb": "1f468-1f3fd-200d-1f4bb",
    "1f468-1f3fe-1f4bb": "1f468-1f3fe-200d-1f4bb",
    "1f468-1f3ff-1f4bb": "1f468-1f3ff-200d-1f4bb",
    "1f9d1-1f4bc": "1f9d1-200d-1f4bc",
    "1f9d1-1f3fb-1f4bc": "1f9d1-1f3fb-200d-1f4bc",
    "1f9d1-1f3fc-1f4bc": "1f9d1-1f3fc-200d-1f4bc",
    "1f9d1-1f3fd-1f4bc": "1f9d1-1f3fd-200d-1f4bc",
    "1f9d1-1f3fe-1f4bc": "1f9d1-1f3fe-200d-1f4bc",
    "1f9d1-1f3ff-1f4bc": "1f9d1-1f3ff-200d-1f4bc",
    "1f469-1f4bc": "1f469-200d-1f4bc",
    "1f469-1f3fb-1f4bc": "1f469-1f3fb-200d-1f4bc",
    "1f469-1f3fc-1f4bc": "1f469-1f3fc-200d-1f4bc",
    "1f469-1f3fd-1f4bc": "1f469-1f3fd-200d-1f4bc",
    "1f469-1f3fe-1f4bc": "1f469-1f3fe-200d-1f4bc",
    "1f469-1f3ff-1f4bc": "1f469-1f3ff-200d-1f4bc",
    "1f468-1f4bc": "1f468-200d-1f4bc",
    "1f468-1f3fb-1f4bc": "1f468-1f3fb-200d-1f4bc",
    "1f468-1f3fc-1f4bc": "1f468-1f3fc-200d-1f4bc",
    "1f468-1f3fd-1f4bc": "1f468-1f3fd-200d-1f4bc",
    "1f468-1f3fe-1f4bc": "1f468-1f3fe-200d-1f4bc",
    "1f468-1f3ff-1f4bc": "1f468-1f3ff-200d-1f4bc",
    "1f9d1-1f527": "1f9d1-200d-1f527",
    "1f9d1-1f3fb-1f527": "1f9d1-1f3fb-200d-1f527",
    "1f9d1-1f3fc-1f527": "1f9d1-1f3fc-200d-1f527",
    "1f9d1-1f3fd-1f527": "1f9d1-1f3fd-200d-1f527",
    "1f9d1-1f3fe-1f527": "1f9d1-1f3fe-200d-1f527",
    "1f9d1-1f3ff-1f527": "1f9d1-1f3ff-200d-1f527",
    "1f469-1f527": "1f469-200d-1f527",
    "1f469-1f3fb-1f527": "1f469-1f3fb-200d-1f527",
    "1f469-1f3fc-1f527": "1f469-1f3fc-200d-1f527",
    "1f469-1f3fd-1f527": "1f469-1f3fd-200d-1f527",
    "1f469-1f3fe-1f527": "1f469-1f3fe-200d-1f527",
    "1f469-1f3ff-1f527": "1f469-1f3ff-200d-1f527",
    "1f468-1f527": "1f468-200d-1f527",
    "1f468-1f3fb-1f527": "1f468-1f3fb-200d-1f527",
    "1f468-1f3fc-1f527": "1f468-1f3fc-200d-1f527",
    "1f468-1f3fd-1f527": "1f468-1f3fd-200d-1f527",
    "1f468-1f3fe-1f527": "1f468-1f3fe-200d-1f527",
    "1f468-1f3ff-1f527": "1f468-1f3ff-200d-1f527",
    "1f9d1-1f52c": "1f9d1-200d-1f52c",
    "1f9d1-1f3fb-1f52c": "1f9d1-1f3fb-200d-1f52c",
    "1f9d1-1f3fc-1f52c": "1f9d1-1f3fc-200d-1f52c",
    "1f9d1-1f3fd-1f52c": "1f9d1-1f3fd-200d-1f52c",
    "1f9d1-1f3fe-1f52c": "1f9d1-1f3fe-200d-1f52c",
    "1f9d1-1f3ff-1f52c": "1f9d1-1f3ff-200d-1f52c",
    "1f469-1f52c": "1f469-200d-1f52c",
    "1f469-1f3fb-1f52c": "1f469-1f3fb-200d-1f52c",
    "1f469-1f3fc-1f52c": "1f469-1f3fc-200d-1f52c",
    "1f469-1f3fd-1f52c": "1f469-1f3fd-200d-1f52c",
    "1f469-1f3fe-1f52c": "1f469-1f3fe-200d-1f52c",
    "1f469-1f3ff-1f52c": "1f469-1f3ff-200d-1f52c",
    "1f468-1f52c": "1f468-200d-1f52c",
    "1f468-1f3fb-1f52c": "1f468-1f3fb-200d-1f52c",
    "1f468-1f3fc-1f52c": "1f468-1f3fc-200d-1f52c",
    "1f468-1f3fd-1f52c": "1f468-1f3fd-200d-1f52c",
    "1f468-1f3fe-1f52c": "1f468-1f3fe-200d-1f52c",
    "1f468-1f3ff-1f52c": "1f468-1f3ff-200d-1f52c",
    "1f9d1-1f3a8": "1f9d1-200d-1f3a8",
    "1f9d1-1f3fb-1f3a8": "1f9d1-1f3fb-200d-1f3a8",
    "1f9d1-1f3fc-1f3a8": "1f9d1-1f3fc-200d-1f3a8",
    "1f9d1-1f3fd-1f3a8": "1f9d1-1f3fd-200d-1f3a8",
    "1f9d1-1f3fe-1f3a8": "1f9d1-1f3fe-200d-1f3a8",
    "1f9d1-1f3ff-1f3a8": "1f9d1-1f3ff-200d-1f3a8",
    "1f469-1f3a8": "1f469-200d-1f3a8",
    "1f469-1f3fb-1f3a8": "1f469-1f3fb-200d-1f3a8",
    "1f469-1f3fc-1f3a8": "1f469-1f3fc-200d-1f3a8",
    "1f469-1f3fd-1f3a8": "1f469-1f3fd-200d-1f3a8",
    "1f469-1f3fe-1f3a8": "1f469-1f3fe-200d-1f3a8",
    "1f469-1f3ff-1f3a8": "1f469-1f3ff-200d-1f3a8",
    "1f468-1f3a8": "1f468-200d-1f3a8",
    "1f468-1f3fb-1f3a8": "1f468-1f3fb-200d-1f3a8",
    "1f468-1f3fc-1f3a8": "1f468-1f3fc-200d-1f3a8",
    "1f468-1f3fd-1f3a8": "1f468-1f3fd-200d-1f3a8",
    "1f468-1f3fe-1f3a8": "1f468-1f3fe-200d-1f3a8",
    "1f468-1f3ff-1f3a8": "1f468-1f3ff-200d-1f3a8",
    "1f9d1-1f692": "1f9d1-200d-1f692",
    "1f9d1-1f3fb-1f692": "1f9d1-1f3fb-200d-1f692",
    "1f9d1-1f3fc-1f692": "1f9d1-1f3fc-200d-1f692",
    "1f9d1-1f3fd-1f692": "1f9d1-1f3fd-200d-1f692",
    "1f9d1-1f3fe-1f692": "1f9d1-1f3fe-200d-1f692",
    "1f9d1-1f3ff-1f692": "1f9d1-1f3ff-200d-1f692",
    "1f469-1f692": "1f469-200d-1f692",
    "1f469-1f3fb-1f692": "1f469-1f3fb-200d-1f692",
    "1f469-1f3fc-1f692": "1f469-1f3fc-200d-1f692",
    "1f469-1f3fd-1f692": "1f469-1f3fd-200d-1f692",
    "1f469-1f3fe-1f692": "1f469-1f3fe-200d-1f692",
    "1f469-1f3ff-1f692": "1f469-1f3ff-200d-1f692",
    "1f468-1f692": "1f468-200d-1f692",
    "1f468-1f3fb-1f692": "1f468-1f3fb-200d-1f692",
    "1f468-1f3fc-1f692": "1f468-1f3fc-200d-1f692",
    "1f468-1f3fd-1f692": "1f468-1f3fd-200d-1f692",
    "1f468-1f3fe-1f692": "1f468-1f3fe-200d-1f692",
    "1f468-1f3ff-1f692": "1f468-1f3ff-200d-1f692",
    "1f9d1-2708": "1f9d1-200d-2708-fe0f",
    "1f9d1-1f3fb-2708": "1f9d1-1f3fb-200d-2708-fe0f",
    "1f9d1-1f3fc-2708": "1f9d1-1f3fc-200d-2708-fe0f",
    "1f9d1-1f3fd-2708": "1f9d1-1f3fd-200d-2708-fe0f",
    "1f9d1-1f3fe-2708": "1f9d1-1f3fe-200d-2708-fe0f",
    "1f9d1-1f3ff-2708": "1f9d1-1f3ff-200d-2708-fe0f",
    "1f469-2708": "1f469-200d-2708-fe0f",
    "1f469-1f3fb-2708": "1f469-1f3fb-200d-2708-fe0f",
    "1f469-1f3fc-2708": "1f469-1f3fc-200d-2708-fe0f",
    "1f469-1f3fd-2708": "1f469-1f3fd-200d-2708-fe0f",
    "1f469-1f3fe-2708": "1f469-1f3fe-200d-2708-fe0f",
    "1f469-1f3ff-2708": "1f469-1f3ff-200d-2708-fe0f",
    "1f468-2708": "1f468-200d-2708-fe0f",
    "1f468-1f3fb-2708": "1f468-1f3fb-200d-2708-fe0f",
    "1f468-1f3fc-2708": "1f468-1f3fc-200d-2708-fe0f",
    "1f468-1f3fd-2708": "1f468-1f3fd-200d-2708-fe0f",
    "1f468-1f3fe-2708": "1f468-1f3fe-200d-2708-fe0f",
    "1f468-1f3ff-2708": "1f468-1f3ff-200d-2708-fe0f",
    "1f9d1-1f680": "1f9d1-200d-1f680",
    "1f9d1-1f3fb-1f680": "1f9d1-1f3fb-200d-1f680",
    "1f9d1-1f3fc-1f680": "1f9d1-1f3fc-200d-1f680",
    "1f9d1-1f3fd-1f680": "1f9d1-1f3fd-200d-1f680",
    "1f9d1-1f3fe-1f680": "1f9d1-1f3fe-200d-1f680",
    "1f9d1-1f3ff-1f680": "1f9d1-1f3ff-200d-1f680",
    "1f469-1f680": "1f469-200d-1f680",
    "1f469-1f3fb-1f680": "1f469-1f3fb-200d-1f680",
    "1f469-1f3fc-1f680": "1f469-1f3fc-200d-1f680",
    "1f469-1f3fd-1f680": "1f469-1f3fd-200d-1f680",
    "1f469-1f3fe-1f680": "1f469-1f3fe-200d-1f680",
    "1f469-1f3ff-1f680": "1f469-1f3ff-200d-1f680",
    "1f468-1f680": "1f468-200d-1f680",
    "1f468-1f3fb-1f680": "1f468-1f3fb-200d-1f680",
    "1f468-1f3fc-1f680": "1f468-1f3fc-200d-1f680",
    "1f468-1f3fd-1f680": "1f468-1f3fd-200d-1f680",
    "1f468-1f3fe-1f680": "1f468-1f3fe-200d-1f680",
    "1f468-1f3ff-1f680": "1f468-1f3ff-200d-1f680",
    "1f9d1-2696": "1f9d1-200d-2696-fe0f",
    "1f9d1-1f3fb-2696": "1f9d1-1f3fb-200d-2696-fe0f",
    "1f9d1-1f3fc-2696": "1f9d1-1f3fc-200d-2696-fe0f",
    "1f9d1-1f3fd-2696": "1f9d1-1f3fd-200d-2696-fe0f",
    "1f9d1-1f3fe-2696": "1f9d1-1f3fe-200d-2696-fe0f",
    "1f9d1-1f3ff-2696": "1f9d1-1f3ff-200d-2696-fe0f",
    "1f469-2696": "1f469-200d-2696-fe0f",
    "1f469-1f3fb-2696": "1f469-1f3fb-200d-2696-fe0f",
    "1f469-1f3fc-2696": "1f469-1f3fc-200d-2696-fe0f",
    "1f469-1f3fd-2696": "1f469-1f3fd-200d-2696-fe0f",
    "1f469-1f3fe-2696": "1f469-1f3fe-200d-2696-fe0f",
    "1f469-1f3ff-2696": "1f469-1f3ff-200d-2696-fe0f",
    "1f468-2696": "1f468-200d-2696-fe0f",
    "1f468-1f3fb-2696": "1f468-1f3fb-200d-2696-fe0f",
    "1f468-1f3fc-2696": "1f468-1f3fc-200d-2696-fe0f",
    "1f468-1f3fd-2696": "1f468-1f3fd-200d-2696-fe0f",
    "1f468-1f3fe-2696": "1f468-1f3fe-200d-2696-fe0f",
    "1f468-1f3ff-2696": "1f468-1f3ff-200d-2696-fe0f",
    "1f470-2640": "1f470-200d-2640-fe0f",
    "1f470-1f3fb-2640": "1f470-1f3fb-200d-2640-fe0f",
    "1f470-1f3fc-2640": "1f470-1f3fc-200d-2640-fe0f",
    "1f470-1f3fd-2640": "1f470-1f3fd-200d-2640-fe0f",
    "1f470-1f3fe-2640": "1f470-1f3fe-200d-2640-fe0f",
    "1f470-1f3ff-2640": "1f470-1f3ff-200d-2640-fe0f",
    "1f470-2642": "1f470-200d-2642-fe0f",
    "1f470-1f3fb-2642": "1f470-1f3fb-200d-2642-fe0f",
    "1f470-1f3fc-2642": "1f470-1f3fc-200d-2642-fe0f",
    "1f470-1f3fd-2642": "1f470-1f3fd-200d-2642-fe0f",
    "1f470-1f3fe-2642": "1f470-1f3fe-200d-2642-fe0f",
    "1f470-1f3ff-2642": "1f470-1f3ff-200d-2642-fe0f",
    "1f935-2640": "1f935-200d-2640-fe0f",
    "1f935-1f3fb-2640": "1f935-1f3fb-200d-2640-fe0f",
    "1f935-1f3fc-2640": "1f935-1f3fc-200d-2640-fe0f",
    "1f935-1f3fd-2640": "1f935-1f3fd-200d-2640-fe0f",
    "1f935-1f3fe-2640": "1f935-1f3fe-200d-2640-fe0f",
    "1f935-1f3ff-2640": "1f935-1f3ff-200d-2640-fe0f",
    "1f935-2642": "1f935-200d-2642-fe0f",
    "1f935-1f3fb-2642": "1f935-1f3fb-200d-2642-fe0f",
    "1f935-1f3fc-2642": "1f935-1f3fc-200d-2642-fe0f",
    "1f935-1f3fd-2642": "1f935-1f3fd-200d-2642-fe0f",
    "1f935-1f3fe-2642": "1f935-1f3fe-200d-2642-fe0f",
    "1f935-1f3ff-2642": "1f935-1f3ff-200d-2642-fe0f",
    "1f9b8-2640": "1f9b8-200d-2640-fe0f",
    "1f9b8-1f3fb-2640": "1f9b8-1f3fb-200d-2640-fe0f",
    "1f9b8-1f3fc-2640": "1f9b8-1f3fc-200d-2640-fe0f",
    "1f9b8-1f3fd-2640": "1f9b8-1f3fd-200d-2640-fe0f",
    "1f9b8-1f3fe-2640": "1f9b8-1f3fe-200d-2640-fe0f",
    "1f9b8-1f3ff-2640": "1f9b8-1f3ff-200d-2640-fe0f",
    "1f9b8-2642": "1f9b8-200d-2642-fe0f",
    "1f9b8-1f3fb-2642": "1f9b8-1f3fb-200d-2642-fe0f",
    "1f9b8-1f3fc-2642": "1f9b8-1f3fc-200d-2642-fe0f",
    "1f9b8-1f3fd-2642": "1f9b8-1f3fd-200d-2642-fe0f",
    "1f9b8-1f3fe-2642": "1f9b8-1f3fe-200d-2642-fe0f",
    "1f9b8-1f3ff-2642": "1f9b8-1f3ff-200d-2642-fe0f",
    "1f9b9-2640": "1f9b9-200d-2640-fe0f",
    "1f9b9-1f3fb-2640": "1f9b9-1f3fb-200d-2640-fe0f",
    "1f9b9-1f3fc-2640": "1f9b9-1f3fc-200d-2640-fe0f",
    "1f9b9-1f3fd-2640": "1f9b9-1f3fd-200d-2640-fe0f",
    "1f9b9-1f3fe-2640": "1f9b9-1f3fe-200d-2640-fe0f",
    "1f9b9-1f3ff-2640": "1f9b9-1f3ff-200d-2640-fe0f",
    "1f9b9-2642": "1f9b9-200d-2642-fe0f",
    "1f9b9-1f3fb-2642": "1f9b9-1f3fb-200d-2642-fe0f",
    "1f9b9-1f3fc-2642": "1f9b9-1f3fc-200d-2642-fe0f",
    "1f9b9-1f3fd-2642": "1f9b9-1f3fd-200d-2642-fe0f",
    "1f9b9-1f3fe-2642": "1f9b9-1f3fe-200d-2642-fe0f",
    "1f9b9-1f3ff-2642": "1f9b9-1f3ff-200d-2642-fe0f",
    "1f9d1-1f384": "1f9d1-200d-1f384",
    "1f9d1-1f3fb-1f384": "1f9d1-1f3fb-200d-1f384",
    "1f9d1-1f3fc-1f384": "1f9d1-1f3fc-200d-1f384",
    "1f9d1-1f3fd-1f384": "1f9d1-1f3fd-200d-1f384",
    "1f9d1-1f3fe-1f384": "1f9d1-1f3fe-200d-1f384",
    "1f9d1-1f3ff-1f384": "1f9d1-1f3ff-200d-1f384",
    "1f9d9-2640": "1f9d9-200d-2640-fe0f",
    "1f9d9-1f3fb-2640": "1f9d9-1f3fb-200d-2640-fe0f",
    "1f9d9-1f3fc-2640": "1f9d9-1f3fc-200d-2640-fe0f",
    "1f9d9-1f3fd-2640": "1f9d9-1f3fd-200d-2640-fe0f",
    "1f9d9-1f3fe-2640": "1f9d9-1f3fe-200d-2640-fe0f",
    "1f9d9-1f3ff-2640": "1f9d9-1f3ff-200d-2640-fe0f",
    "1f9d9-2642": "1f9d9-200d-2642-fe0f",
    "1f9d9-1f3fb-2642": "1f9d9-1f3fb-200d-2642-fe0f",
    "1f9d9-1f3fc-2642": "1f9d9-1f3fc-200d-2642-fe0f",
    "1f9d9-1f3fd-2642": "1f9d9-1f3fd-200d-2642-fe0f",
    "1f9d9-1f3fe-2642": "1f9d9-1f3fe-200d-2642-fe0f",
    "1f9d9-1f3ff-2642": "1f9d9-1f3ff-200d-2642-fe0f",
    "1f9dd-2640": "1f9dd-200d-2640-fe0f",
    "1f9dd-1f3fb-2640": "1f9dd-1f3fb-200d-2640-fe0f",
    "1f9dd-1f3fc-2640": "1f9dd-1f3fc-200d-2640-fe0f",
    "1f9dd-1f3fd-2640": "1f9dd-1f3fd-200d-2640-fe0f",
    "1f9dd-1f3fe-2640": "1f9dd-1f3fe-200d-2640-fe0f",
    "1f9dd-1f3ff-2640": "1f9dd-1f3ff-200d-2640-fe0f",
    "1f9dd-2642": "1f9dd-200d-2642-fe0f",
    "1f9dd-1f3fb-2642": "1f9dd-1f3fb-200d-2642-fe0f",
    "1f9dd-1f3fc-2642": "1f9dd-1f3fc-200d-2642-fe0f",
    "1f9dd-1f3fd-2642": "1f9dd-1f3fd-200d-2642-fe0f",
    "1f9dd-1f3fe-2642": "1f9dd-1f3fe-200d-2642-fe0f",
    "1f9dd-1f3ff-2642": "1f9dd-1f3ff-200d-2642-fe0f",
    "1f9db-2640": "1f9db-200d-2640-fe0f",
    "1f9db-1f3fb-2640": "1f9db-1f3fb-200d-2640-fe0f",
    "1f9db-1f3fc-2640": "1f9db-1f3fc-200d-2640-fe0f",
    "1f9db-1f3fd-2640": "1f9db-1f3fd-200d-2640-fe0f",
    "1f9db-1f3fe-2640": "1f9db-1f3fe-200d-2640-fe0f",
    "1f9db-1f3ff-2640": "1f9db-1f3ff-200d-2640-fe0f",
    "1f9db-2642": "1f9db-200d-2642-fe0f",
    "1f9db-1f3fb-2642": "1f9db-1f3fb-200d-2642-fe0f",
    "1f9db-1f3fc-2642": "1f9db-1f3fc-200d-2642-fe0f",
    "1f9db-1f3fd-2642": "1f9db-1f3fd-200d-2642-fe0f",
    "1f9db-1f3fe-2642": "1f9db-1f3fe-200d-2642-fe0f",
    "1f9db-1f3ff-2642": "1f9db-1f3ff-200d-2642-fe0f",
    "1f9df-2640": "1f9df-200d-2640-fe0f",
    "1f9df-2642": "1f9df-200d-2642-fe0f",
    "1f9de-2640": "1f9de-200d-2640-fe0f",
    "1f9de-2642": "1f9de-200d-2642-fe0f",
    "1f9dc-2640": "1f9dc-200d-2640-fe0f",
    "1f9dc-1f3fb-2640": "1f9dc-1f3fb-200d-2640-fe0f",
    "1f9dc-1f3fc-2640": "1f9dc-1f3fc-200d-2640-fe0f",
    "1f9dc-1f3fd-2640": "1f9dc-1f3fd-200d-2640-fe0f",
    "1f9dc-1f3fe-2640": "1f9dc-1f3fe-200d-2640-fe0f",
    "1f9dc-1f3ff-2640": "1f9dc-1f3ff-200d-2640-fe0f",
    "1f9dc-2642": "1f9dc-200d-2642-fe0f",
    "1f9dc-1f3fb-2642": "1f9dc-1f3fb-200d-2642-fe0f",
    "1f9dc-1f3fc-2642": "1f9dc-1f3fc-200d-2642-fe0f",
    "1f9dc-1f3fd-2642": "1f9dc-1f3fd-200d-2642-fe0f",
    "1f9dc-1f3fe-2642": "1f9dc-1f3fe-200d-2642-fe0f",
    "1f9dc-1f3ff-2642": "1f9dc-1f3ff-200d-2642-fe0f",
    "1f9da-2640": "1f9da-200d-2640-fe0f",
    "1f9da-1f3fb-2640": "1f9da-1f3fb-200d-2640-fe0f",
    "1f9da-1f3fc-2640": "1f9da-1f3fc-200d-2640-fe0f",
    "1f9da-1f3fd-2640": "1f9da-1f3fd-200d-2640-fe0f",
    "1f9da-1f3fe-2640": "1f9da-1f3fe-200d-2640-fe0f",
    "1f9da-1f3ff-2640": "1f9da-1f3ff-200d-2640-fe0f",
    "1f9da-2642": "1f9da-200d-2642-fe0f",
    "1f9da-1f3fb-2642": "1f9da-1f3fb-200d-2642-fe0f",
    "1f9da-1f3fc-2642": "1f9da-1f3fc-200d-2642-fe0f",
    "1f9da-1f3fd-2642": "1f9da-1f3fd-200d-2642-fe0f",
    "1f9da-1f3fe-2642": "1f9da-1f3fe-200d-2642-fe0f",
    "1f9da-1f3ff-2642": "1f9da-1f3ff-200d-2642-fe0f",
    "1f9d1-1f37c": "1f9d1-200d-1f37c",
    "1f9d1-1f3fb-1f37c": "1f9d1-1f3fb-200d-1f37c",
    "1f9d1-1f3fc-1f37c": "1f9d1-1f3fc-200d-1f37c",
    "1f9d1-1f3fd-1f37c": "1f9d1-1f3fd-200d-1f37c",
    "1f9d1-1f3fe-1f37c": "1f9d1-1f3fe-200d-1f37c",
    "1f9d1-1f3ff-1f37c": "1f9d1-1f3ff-200d-1f37c",
    "1f469-1f37c": "1f469-200d-1f37c",
    "1f469-1f3fb-1f37c": "1f469-1f3fb-200d-1f37c",
    "1f469-1f3fc-1f37c": "1f469-1f3fc-200d-1f37c",
    "1f469-1f3fd-1f37c": "1f469-1f3fd-200d-1f37c",
    "1f469-1f3fe-1f37c": "1f469-1f3fe-200d-1f37c",
    "1f469-1f3ff-1f37c": "1f469-1f3ff-200d-1f37c",
    "1f468-1f37c": "1f468-200d-1f37c",
    "1f468-1f3fb-1f37c": "1f468-1f3fb-200d-1f37c",
    "1f468-1f3fc-1f37c": "1f468-1f3fc-200d-1f37c",
    "1f468-1f3fd-1f37c": "1f468-1f3fd-200d-1f37c",
    "1f468-1f3fe-1f37c": "1f468-1f3fe-200d-1f37c",
    "1f468-1f3ff-1f37c": "1f468-1f3ff-200d-1f37c",
    "1f647-2640": "1f647-200d-2640-fe0f",
    "1f647-1f3fb-2640": "1f647-1f3fb-200d-2640-fe0f",
    "1f647-1f3fc-2640": "1f647-1f3fc-200d-2640-fe0f",
    "1f647-1f3fd-2640": "1f647-1f3fd-200d-2640-fe0f",
    "1f647-1f3fe-2640": "1f647-1f3fe-200d-2640-fe0f",
    "1f647-1f3ff-2640": "1f647-1f3ff-200d-2640-fe0f",
    "1f647-2642": "1f647-200d-2642-fe0f",
    "1f647-1f3fb-2642": "1f647-1f3fb-200d-2642-fe0f",
    "1f647-1f3fc-2642": "1f647-1f3fc-200d-2642-fe0f",
    "1f647-1f3fd-2642": "1f647-1f3fd-200d-2642-fe0f",
    "1f647-1f3fe-2642": "1f647-1f3fe-200d-2642-fe0f",
    "1f647-1f3ff-2642": "1f647-1f3ff-200d-2642-fe0f",
    "1f481-2640": "1f481-200d-2640-fe0f",
    "1f481-1f3fb-2640": "1f481-1f3fb-200d-2640-fe0f",
    "1f481-1f3fc-2640": "1f481-1f3fc-200d-2640-fe0f",
    "1f481-1f3fd-2640": "1f481-1f3fd-200d-2640-fe0f",
    "1f481-1f3fe-2640": "1f481-1f3fe-200d-2640-fe0f",
    "1f481-1f3ff-2640": "1f481-1f3ff-200d-2640-fe0f",
    "1f481-2642": "1f481-200d-2642-fe0f",
    "1f481-1f3fb-2642": "1f481-1f3fb-200d-2642-fe0f",
    "1f481-1f3fc-2642": "1f481-1f3fc-200d-2642-fe0f",
    "1f481-1f3fd-2642": "1f481-1f3fd-200d-2642-fe0f",
    "1f481-1f3fe-2642": "1f481-1f3fe-200d-2642-fe0f",
    "1f481-1f3ff-2642": "1f481-1f3ff-200d-2642-fe0f",
    "1f645-2640": "1f645-200d-2640-fe0f",
    "1f645-1f3fb-2640": "1f645-1f3fb-200d-2640-fe0f",
    "1f645-1f3fc-2640": "1f645-1f3fc-200d-2640-fe0f",
    "1f645-1f3fd-2640": "1f645-1f3fd-200d-2640-fe0f",
    "1f645-1f3fe-2640": "1f645-1f3fe-200d-2640-fe0f",
    "1f645-1f3ff-2640": "1f645-1f3ff-200d-2640-fe0f",
    "1f645-2642": "1f645-200d-2642-fe0f",
    "1f645-1f3fb-2642": "1f645-1f3fb-200d-2642-fe0f",
    "1f645-1f3fc-2642": "1f645-1f3fc-200d-2642-fe0f",
    "1f645-1f3fd-2642": "1f645-1f3fd-200d-2642-fe0f",
    "1f645-1f3fe-2642": "1f645-1f3fe-200d-2642-fe0f",
    "1f645-1f3ff-2642": "1f645-1f3ff-200d-2642-fe0f",
    "1f646-2640": "1f646-200d-2640-fe0f",
    "1f646-1f3fb-2640": "1f646-1f3fb-200d-2640-fe0f",
    "1f646-1f3fc-2640": "1f646-1f3fc-200d-2640-fe0f",
    "1f646-1f3fd-2640": "1f646-1f3fd-200d-2640-fe0f",
    "1f646-1f3fe-2640": "1f646-1f3fe-200d-2640-fe0f",
    "1f646-1f3ff-2640": "1f646-1f3ff-200d-2640-fe0f",
    "1f646-2642": "1f646-200d-2642-fe0f",
    "1f646-1f3fb-2642": "1f646-1f3fb-200d-2642-fe0f",
    "1f646-1f3fc-2642": "1f646-1f3fc-200d-2642-fe0f",
    "1f646-1f3fd-2642": "1f646-1f3fd-200d-2642-fe0f",
    "1f646-1f3fe-2642": "1f646-1f3fe-200d-2642-fe0f",
    "1f646-1f3ff-2642": "1f646-1f3ff-200d-2642-fe0f",
    "1f64b-2640": "1f64b-200d-2640-fe0f",
    "1f64b-1f3fb-2640": "1f64b-1f3fb-200d-2640-fe0f",
    "1f64b-1f3fc-2640": "1f64b-1f3fc-200d-2640-fe0f",
    "1f64b-1f3fd-2640": "1f64b-1f3fd-200d-2640-fe0f",
    "1f64b-1f3fe-2640": "1f64b-1f3fe-200d-2640-fe0f",
    "1f64b-1f3ff-2640": "1f64b-1f3ff-200d-2640-fe0f",
    "1f64b-2642": "1f64b-200d-2642-fe0f",
    "1f64b-1f3fb-2642": "1f64b-1f3fb-200d-2642-fe0f",
    "1f64b-1f3fc-2642": "1f64b-1f3fc-200d-2642-fe0f",
    "1f64b-1f3fd-2642": "1f64b-1f3fd-200d-2642-fe0f",
    "1f64b-1f3fe-2642": "1f64b-1f3fe-200d-2642-fe0f",
    "1f64b-1f3ff-2642": "1f64b-1f3ff-200d-2642-fe0f",
    "1f9cf-2640": "1f9cf-200d-2640-fe0f",
    "1f9cf-1f3fb-2640": "1f9cf-1f3fb-200d-2640-fe0f",
    "1f9cf-1f3fc-2640": "1f9cf-1f3fc-200d-2640-fe0f",
    "1f9cf-1f3fd-2640": "1f9cf-1f3fd-200d-2640-fe0f",
    "1f9cf-1f3fe-2640": "1f9cf-1f3fe-200d-2640-fe0f",
    "1f9cf-1f3ff-2640": "1f9cf-1f3ff-200d-2640-fe0f",
    "1f9cf-2642": "1f9cf-200d-2642-fe0f",
    "1f9cf-1f3fb-2642": "1f9cf-1f3fb-200d-2642-fe0f",
    "1f9cf-1f3fc-2642": "1f9cf-1f3fc-200d-2642-fe0f",
    "1f9cf-1f3fd-2642": "1f9cf-1f3fd-200d-2642-fe0f",
    "1f9cf-1f3fe-2642": "1f9cf-1f3fe-200d-2642-fe0f",
    "1f9cf-1f3ff-2642": "1f9cf-1f3ff-200d-2642-fe0f",
    "1f926-2640": "1f926-200d-2640-fe0f",
    "1f926-1f3fb-2640": "1f926-1f3fb-200d-2640-fe0f",
    "1f926-1f3fc-2640": "1f926-1f3fc-200d-2640-fe0f",
    "1f926-1f3fd-2640": "1f926-1f3fd-200d-2640-fe0f",
    "1f926-1f3fe-2640": "1f926-1f3fe-200d-2640-fe0f",
    "1f926-1f3ff-2640": "1f926-1f3ff-200d-2640-fe0f",
    "1f926-2642": "1f926-200d-2642-fe0f",
    "1f926-1f3fb-2642": "1f926-1f3fb-200d-2642-fe0f",
    "1f926-1f3fc-2642": "1f926-1f3fc-200d-2642-fe0f",
    "1f926-1f3fd-2642": "1f926-1f3fd-200d-2642-fe0f",
    "1f926-1f3fe-2642": "1f926-1f3fe-200d-2642-fe0f",
    "1f926-1f3ff-2642": "1f926-1f3ff-200d-2642-fe0f",
    "1f937-2640": "1f937-200d-2640-fe0f",
    "1f937-1f3fb-2640": "1f937-1f3fb-200d-2640-fe0f",
    "1f937-1f3fc-2640": "1f937-1f3fc-200d-2640-fe0f",
    "1f937-1f3fd-2640": "1f937-1f3fd-200d-2640-fe0f",
    "1f937-1f3fe-2640": "1f937-1f3fe-200d-2640-fe0f",
    "1f937-1f3ff-2640": "1f937-1f3ff-200d-2640-fe0f",
    "1f937-2642": "1f937-200d-2642-fe0f",
    "1f937-1f3fb-2642": "1f937-1f3fb-200d-2642-fe0f",
    "1f937-1f3fc-2642": "1f937-1f3fc-200d-2642-fe0f",
    "1f937-1f3fd-2642": "1f937-1f3fd-200d-2642-fe0f",
    "1f937-1f3fe-2642": "1f937-1f3fe-200d-2642-fe0f",
    "1f937-1f3ff-2642": "1f937-1f3ff-200d-2642-fe0f",
    "1f64e-2640": "1f64e-200d-2640-fe0f",
    "1f64e-1f3fb-2640": "1f64e-1f3fb-200d-2640-fe0f",
    "1f64e-1f3fc-2640": "1f64e-1f3fc-200d-2640-fe0f",
    "1f64e-1f3fd-2640": "1f64e-1f3fd-200d-2640-fe0f",
    "1f64e-1f3fe-2640": "1f64e-1f3fe-200d-2640-fe0f",
    "1f64e-1f3ff-2640": "1f64e-1f3ff-200d-2640-fe0f",
    "1f64e-2642": "1f64e-200d-2642-fe0f",
    "1f64e-1f3fb-2642": "1f64e-1f3fb-200d-2642-fe0f",
    "1f64e-1f3fc-2642": "1f64e-1f3fc-200d-2642-fe0f",
    "1f64e-1f3fd-2642": "1f64e-1f3fd-200d-2642-fe0f",
    "1f64e-1f3fe-2642": "1f64e-1f3fe-200d-2642-fe0f",
    "1f64e-1f3ff-2642": "1f64e-1f3ff-200d-2642-fe0f",
    "1f64d-2640": "1f64d-200d-2640-fe0f",
    "1f64d-1f3fb-2640": "1f64d-1f3fb-200d-2640-fe0f",
    "1f64d-1f3fc-2640": "1f64d-1f3fc-200d-2640-fe0f",
    "1f64d-1f3fd-2640": "1f64d-1f3fd-200d-2640-fe0f",
    "1f64d-1f3fe-2640": "1f64d-1f3fe-200d-2640-fe0f",
    "1f64d-1f3ff-2640": "1f64d-1f3ff-200d-2640-fe0f",
    "1f64d-2642": "1f64d-200d-2642-fe0f",
    "1f64d-1f3fb-2642": "1f64d-1f3fb-200d-2642-fe0f",
    "1f64d-1f3fc-2642": "1f64d-1f3fc-200d-2642-fe0f",
    "1f64d-1f3fd-2642": "1f64d-1f3fd-200d-2642-fe0f",
    "1f64d-1f3fe-2642": "1f64d-1f3fe-200d-2642-fe0f",
    "1f64d-1f3ff-2642": "1f64d-1f3ff-200d-2642-fe0f",
    "1f487-2640": "1f487-200d-2640-fe0f",
    "1f487-1f3fb-2640": "1f487-1f3fb-200d-2640-fe0f",
    "1f487-1f3fc-2640": "1f487-1f3fc-200d-2640-fe0f",
    "1f487-1f3fd-2640": "1f487-1f3fd-200d-2640-fe0f",
    "1f487-1f3fe-2640": "1f487-1f3fe-200d-2640-fe0f",
    "1f487-1f3ff-2640": "1f487-1f3ff-200d-2640-fe0f",
    "1f487-2642": "1f487-200d-2642-fe0f",
    "1f487-1f3fb-2642": "1f487-1f3fb-200d-2642-fe0f",
    "1f487-1f3fc-2642": "1f487-1f3fc-200d-2642-fe0f",
    "1f487-1f3fd-2642": "1f487-1f3fd-200d-2642-fe0f",
    "1f487-1f3fe-2642": "1f487-1f3fe-200d-2642-fe0f",
    "1f487-1f3ff-2642": "1f487-1f3ff-200d-2642-fe0f",
    "1f486-2640": "1f486-200d-2640-fe0f",
    "1f486-1f3fb-2640": "1f486-1f3fb-200d-2640-fe0f",
    "1f486-1f3fc-2640": "1f486-1f3fc-200d-2640-fe0f",
    "1f486-1f3fd-2640": "1f486-1f3fd-200d-2640-fe0f",
    "1f486-1f3fe-2640": "1f486-1f3fe-200d-2640-fe0f",
    "1f486-1f3ff-2640": "1f486-1f3ff-200d-2640-fe0f",
    "1f486-2642": "1f486-200d-2642-fe0f",
    "1f486-1f3fb-2642": "1f486-1f3fb-200d-2642-fe0f",
    "1f486-1f3fc-2642": "1f486-1f3fc-200d-2642-fe0f",
    "1f486-1f3fd-2642": "1f486-1f3fd-200d-2642-fe0f",
    "1f486-1f3fe-2642": "1f486-1f3fe-200d-2642-fe0f",
    "1f486-1f3ff-2642": "1f486-1f3ff-200d-2642-fe0f",
    "1f9d6-2640": "1f9d6-200d-2640-fe0f",
    "1f9d6-1f3fb-2640": "1f9d6-1f3fb-200d-2640-fe0f",
    "1f9d6-1f3fc-2640": "1f9d6-1f3fc-200d-2640-fe0f",
    "1f9d6-1f3fd-2640": "1f9d6-1f3fd-200d-2640-fe0f",
    "1f9d6-1f3fe-2640": "1f9d6-1f3fe-200d-2640-fe0f",
    "1f9d6-1f3ff-2640": "1f9d6-1f3ff-200d-2640-fe0f",
    "1f9d6-2642": "1f9d6-200d-2642-fe0f",
    "1f9d6-1f3fb-2642": "1f9d6-1f3fb-200d-2642-fe0f",
    "1f9d6-1f3fc-2642": "1f9d6-1f3fc-200d-2642-fe0f",
    "1f9d6-1f3fd-2642": "1f9d6-1f3fd-200d-2642-fe0f",
    "1f9d6-1f3fe-2642": "1f9d6-1f3fe-200d-2642-fe0f",
    "1f9d6-1f3ff-2642": "1f9d6-1f3ff-200d-2642-fe0f",
    "1f46f-2640": "1f46f-200d-2640-fe0f",
    "1f46f-2642": "1f46f-200d-2642-fe0f",
    "1f574": "1f574-fe0f",
    "1f9d1-1f9bd": "1f9d1-200d-1f9bd",
    "1f9d1-1f3fb-1f9bd": "1f9d1-1f3fb-200d-1f9bd",
    "1f9d1-1f3fc-1f9bd": "1f9d1-1f3fc-200d-1f9bd",
    "1f9d1-1f3fd-1f9bd": "1f9d1-1f3fd-200d-1f9bd",
    "1f9d1-1f3fe-1f9bd": "1f9d1-1f3fe-200d-1f9bd",
    "1f9d1-1f3ff-1f9bd": "1f9d1-1f3ff-200d-1f9bd",
    "1f469-1f9bd": "1f469-200d-1f9bd",
    "1f469-1f3fb-1f9bd": "1f469-1f3fb-200d-1f9bd",
    "1f469-1f3fc-1f9bd": "1f469-1f3fc-200d-1f9bd",
    "1f469-1f3fd-1f9bd": "1f469-1f3fd-200d-1f9bd",
    "1f469-1f3fe-1f9bd": "1f469-1f3fe-200d-1f9bd",
    "1f469-1f3ff-1f9bd": "1f469-1f3ff-200d-1f9bd",
    "1f468-1f9bd": "1f468-200d-1f9bd",
    "1f468-1f3fb-1f9bd": "1f468-1f3fb-200d-1f9bd",
    "1f468-1f3fc-1f9bd": "1f468-1f3fc-200d-1f9bd",
    "1f468-1f3fd-1f9bd": "1f468-1f3fd-200d-1f9bd",
    "1f468-1f3fe-1f9bd": "1f468-1f3fe-200d-1f9bd",
    "1f468-1f3ff-1f9bd": "1f468-1f3ff-200d-1f9bd",
    "1f9d1-1f9bc": "1f9d1-200d-1f9bc",
    "1f9d1-1f3fb-1f9bc": "1f9d1-1f3fb-200d-1f9bc",
    "1f9d1-1f3fc-1f9bc": "1f9d1-1f3fc-200d-1f9bc",
    "1f9d1-1f3fd-1f9bc": "1f9d1-1f3fd-200d-1f9bc",
    "1f9d1-1f3fe-1f9bc": "1f9d1-1f3fe-200d-1f9bc",
    "1f9d1-1f3ff-1f9bc": "1f9d1-1f3ff-200d-1f9bc",
    "1f469-1f9bc": "1f469-200d-1f9bc",
    "1f469-1f3fb-1f9bc": "1f469-1f3fb-200d-1f9bc",
    "1f469-1f3fc-1f9bc": "1f469-1f3fc-200d-1f9bc",
    "1f469-1f3fd-1f9bc": "1f469-1f3fd-200d-1f9bc",
    "1f469-1f3fe-1f9bc": "1f469-1f3fe-200d-1f9bc",
    "1f469-1f3ff-1f9bc": "1f469-1f3ff-200d-1f9bc",
    "1f468-1f9bc": "1f468-200d-1f9bc",
    "1f468-1f3fb-1f9bc": "1f468-1f3fb-200d-1f9bc",
    "1f468-1f3fc-1f9bc": "1f468-1f3fc-200d-1f9bc",
    "1f468-1f3fd-1f9bc": "1f468-1f3fd-200d-1f9bc",
    "1f468-1f3fe-1f9bc": "1f468-1f3fe-200d-1f9bc",
    "1f468-1f3ff-1f9bc": "1f468-1f3ff-200d-1f9bc",
    "1f6b6-2640": "1f6b6-200d-2640-fe0f",
    "1f6b6-1f3fb-2640": "1f6b6-1f3fb-200d-2640-fe0f",
    "1f6b6-1f3fc-2640": "1f6b6-1f3fc-200d-2640-fe0f",
    "1f6b6-1f3fd-2640": "1f6b6-1f3fd-200d-2640-fe0f",
    "1f6b6-1f3fe-2640": "1f6b6-1f3fe-200d-2640-fe0f",
    "1f6b6-1f3ff-2640": "1f6b6-1f3ff-200d-2640-fe0f",
    "1f6b6-2642": "1f6b6-200d-2642-fe0f",
    "1f6b6-1f3fb-2642": "1f6b6-1f3fb-200d-2642-fe0f",
    "1f6b6-1f3fc-2642": "1f6b6-1f3fc-200d-2642-fe0f",
    "1f6b6-1f3fd-2642": "1f6b6-1f3fd-200d-2642-fe0f",
    "1f6b6-1f3fe-2642": "1f6b6-1f3fe-200d-2642-fe0f",
    "1f6b6-1f3ff-2642": "1f6b6-1f3ff-200d-2642-fe0f",
    "1f9d1-1f9af": "1f9d1-200d-1f9af",
    "1f9d1-1f3fb-1f9af": "1f9d1-1f3fb-200d-1f9af",
    "1f9d1-1f3fc-1f9af": "1f9d1-1f3fc-200d-1f9af",
    "1f9d1-1f3fd-1f9af": "1f9d1-1f3fd-200d-1f9af",
    "1f9d1-1f3fe-1f9af": "1f9d1-1f3fe-200d-1f9af",
    "1f9d1-1f3ff-1f9af": "1f9d1-1f3ff-200d-1f9af",
    "1f469-1f9af": "1f469-200d-1f9af",
    "1f469-1f3fb-1f9af": "1f469-1f3fb-200d-1f9af",
    "1f469-1f3fc-1f9af": "1f469-1f3fc-200d-1f9af",
    "1f469-1f3fd-1f9af": "1f469-1f3fd-200d-1f9af",
    "1f469-1f3fe-1f9af": "1f469-1f3fe-200d-1f9af",
    "1f469-1f3ff-1f9af": "1f469-1f3ff-200d-1f9af",
    "1f468-1f9af": "1f468-200d-1f9af",
    "1f468-1f3fb-1f9af": "1f468-1f3fb-200d-1f9af",
    "1f468-1f3fc-1f9af": "1f468-1f3fc-200d-1f9af",
    "1f468-1f3fd-1f9af": "1f468-1f3fd-200d-1f9af",
    "1f468-1f3fe-1f9af": "1f468-1f3fe-200d-1f9af",
    "1f468-1f3ff-1f9af": "1f468-1f3ff-200d-1f9af",
    "1f9ce-2640": "1f9ce-200d-2640-fe0f",
    "1f9ce-1f3fb-2640": "1f9ce-1f3fb-200d-2640-fe0f",
    "1f9ce-1f3fc-2640": "1f9ce-1f3fc-200d-2640-fe0f",
    "1f9ce-1f3fd-2640": "1f9ce-1f3fd-200d-2640-fe0f",
    "1f9ce-1f3fe-2640": "1f9ce-1f3fe-200d-2640-fe0f",
    "1f9ce-1f3ff-2640": "1f9ce-1f3ff-200d-2640-fe0f",
    "1f9ce-2642": "1f9ce-200d-2642-fe0f",
    "1f9ce-1f3fb-2642": "1f9ce-1f3fb-200d-2642-fe0f",
    "1f9ce-1f3fc-2642": "1f9ce-1f3fc-200d-2642-fe0f",
    "1f9ce-1f3fd-2642": "1f9ce-1f3fd-200d-2642-fe0f",
    "1f9ce-1f3fe-2642": "1f9ce-1f3fe-200d-2642-fe0f",
    "1f9ce-1f3ff-2642": "1f9ce-1f3ff-200d-2642-fe0f",
    "1f3c3-2640": "1f3c3-200d-2640-fe0f",
    "1f3c3-1f3fb-2640": "1f3c3-1f3fb-200d-2640-fe0f",
    "1f3c3-1f3fc-2640": "1f3c3-1f3fc-200d-2640-fe0f",
    "1f3c3-1f3fd-2640": "1f3c3-1f3fd-200d-2640-fe0f",
    "1f3c3-1f3fe-2640": "1f3c3-1f3fe-200d-2640-fe0f",
    "1f3c3-1f3ff-2640": "1f3c3-1f3ff-200d-2640-fe0f",
    "1f3c3-2642": "1f3c3-200d-2642-fe0f",
    "1f3c3-1f3fb-2642": "1f3c3-1f3fb-200d-2642-fe0f",
    "1f3c3-1f3fc-2642": "1f3c3-1f3fc-200d-2642-fe0f",
    "1f3c3-1f3fd-2642": "1f3c3-1f3fd-200d-2642-fe0f",
    "1f3c3-1f3fe-2642": "1f3c3-1f3fe-200d-2642-fe0f",
    "1f3c3-1f3ff-2642": "1f3c3-1f3ff-200d-2642-fe0f",
    "1f9cd-2640": "1f9cd-200d-2640-fe0f",
    "1f9cd-1f3fb-2640": "1f9cd-1f3fb-200d-2640-fe0f",
    "1f9cd-1f3fc-2640": "1f9cd-1f3fc-200d-2640-fe0f",
    "1f9cd-1f3fd-2640": "1f9cd-1f3fd-200d-2640-fe0f",
    "1f9cd-1f3fe-2640": "1f9cd-1f3fe-200d-2640-fe0f",
    "1f9cd-1f3ff-2640": "1f9cd-1f3ff-200d-2640-fe0f",
    "1f9cd-2642": "1f9cd-200d-2642-fe0f",
    "1f9cd-1f3fb-2642": "1f9cd-1f3fb-200d-2642-fe0f",
    "1f9cd-1f3fc-2642": "1f9cd-1f3fc-200d-2642-fe0f",
    "1f9cd-1f3fd-2642": "1f9cd-1f3fd-200d-2642-fe0f",
    "1f9cd-1f3fe-2642": "1f9cd-1f3fe-200d-2642-fe0f",
    "1f9cd-1f3ff-2642": "1f9cd-1f3ff-200d-2642-fe0f",
    "1f9d1-1f91d-1f9d1": "1f9d1-200d-1f91d-200d-1f9d1",
    "1f9d1-1f3fb-1f91d-1f9d1-1f3fb": "1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb",
    "1f9d1-1f3fb-1f91d-1f9d1-1f3fc": "1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc",
    "1f9d1-1f3fb-1f91d-1f9d1-1f3fd": "1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd",
    "1f9d1-1f3fb-1f91d-1f9d1-1f3fe": "1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe",
    "1f9d1-1f3fb-1f91d-1f9d1-1f3ff": "1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff",
    "1f9d1-1f3fc-1f91d-1f9d1-1f3fb": "1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb",
    "1f9d1-1f3fc-1f91d-1f9d1-1f3fc": "1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc",
    "1f9d1-1f3fc-1f91d-1f9d1-1f3fd": "1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd",
    "1f9d1-1f3fc-1f91d-1f9d1-1f3fe": "1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe",
    "1f9d1-1f3fc-1f91d-1f9d1-1f3ff": "1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff",
    "1f9d1-1f3fd-1f91d-1f9d1-1f3fb": "1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb",
    "1f9d1-1f3fd-1f91d-1f9d1-1f3fc": "1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc",
    "1f9d1-1f3fd-1f91d-1f9d1-1f3fd": "1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd",
    "1f9d1-1f3fd-1f91d-1f9d1-1f3fe": "1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe",
    "1f9d1-1f3fd-1f91d-1f9d1-1f3ff": "1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff",
    "1f9d1-1f3fe-1f91d-1f9d1-1f3fb": "1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb",
    "1f9d1-1f3fe-1f91d-1f9d1-1f3fc": "1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc",
    "1f9d1-1f3fe-1f91d-1f9d1-1f3fd": "1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd",
    "1f9d1-1f3fe-1f91d-1f9d1-1f3fe": "1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe",
    "1f9d1-1f3fe-1f91d-1f9d1-1f3ff": "1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff",
    "1f9d1-1f3ff-1f91d-1f9d1-1f3fb": "1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb",
    "1f9d1-1f3ff-1f91d-1f9d1-1f3fc": "1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc",
    "1f9d1-1f3ff-1f91d-1f9d1-1f3fd": "1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd",
    "1f9d1-1f3ff-1f91d-1f9d1-1f3fe": "1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe",
    "1f9d1-1f3ff-1f91d-1f9d1-1f3ff": "1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff",
    "1f469-1f3fb-1f91d-1f468-1f3fc": "1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc",
    "1f469-1f3fb-1f91d-1f468-1f3fd": "1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd",
    "1f469-1f3fb-1f91d-1f468-1f3fe": "1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe",
    "1f469-1f3fb-1f91d-1f468-1f3ff": "1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff",
    "1f469-1f3fc-1f91d-1f468-1f3fb": "1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb",
    "1f469-1f3fc-1f91d-1f468-1f3fd": "1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd",
    "1f469-1f3fc-1f91d-1f468-1f3fe": "1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe",
    "1f469-1f3fc-1f91d-1f468-1f3ff": "1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff",
    "1f469-1f3fd-1f91d-1f468-1f3fb": "1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb",
    "1f469-1f3fd-1f91d-1f468-1f3fc": "1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc",
    "1f469-1f3fd-1f91d-1f468-1f3fe": "1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe",
    "1f469-1f3fd-1f91d-1f468-1f3ff": "1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff",
    "1f469-1f3fe-1f91d-1f468-1f3fb": "1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb",
    "1f469-1f3fe-1f91d-1f468-1f3fc": "1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc",
    "1f469-1f3fe-1f91d-1f468-1f3fd": "1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd",
    "1f469-1f3fe-1f91d-1f468-1f3ff": "1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff",
    "1f469-1f3ff-1f91d-1f468-1f3fb": "1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb",
    "1f469-1f3ff-1f91d-1f468-1f3fc": "1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc",
    "1f469-1f3ff-1f91d-1f468-1f3fd": "1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd",
    "1f469-1f3ff-1f91d-1f468-1f3fe": "1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe",
    "1f469-1f3fb-1f91d-1f469-1f3fc": "1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc",
    "1f469-1f3fb-1f91d-1f469-1f3fd": "1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd",
    "1f469-1f3fb-1f91d-1f469-1f3fe": "1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe",
    "1f469-1f3fb-1f91d-1f469-1f3ff": "1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff",
    "1f469-1f3fc-1f91d-1f469-1f3fb": "1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb",
    "1f469-1f3fc-1f91d-1f469-1f3fd": "1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd",
    "1f469-1f3fc-1f91d-1f469-1f3fe": "1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe",
    "1f469-1f3fc-1f91d-1f469-1f3ff": "1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff",
    "1f469-1f3fd-1f91d-1f469-1f3fb": "1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb",
    "1f469-1f3fd-1f91d-1f469-1f3fc": "1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc",
    "1f469-1f3fd-1f91d-1f469-1f3fe": "1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe",
    "1f469-1f3fd-1f91d-1f469-1f3ff": "1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff",
    "1f469-1f3fe-1f91d-1f469-1f3fb": "1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb",
    "1f469-1f3fe-1f91d-1f469-1f3fc": "1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc",
    "1f469-1f3fe-1f91d-1f469-1f3fd": "1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd",
    "1f469-1f3fe-1f91d-1f469-1f3ff": "1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff",
    "1f469-1f3ff-1f91d-1f469-1f3fb": "1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb",
    "1f469-1f3ff-1f91d-1f469-1f3fc": "1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc",
    "1f469-1f3ff-1f91d-1f469-1f3fd": "1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd",
    "1f469-1f3ff-1f91d-1f469-1f3fe": "1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe",
    "1f468-1f3fb-1f91d-1f468-1f3fc": "1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc",
    "1f468-1f3fb-1f91d-1f468-1f3fd": "1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd",
    "1f468-1f3fb-1f91d-1f468-1f3fe": "1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe",
    "1f468-1f3fb-1f91d-1f468-1f3ff": "1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff",
    "1f468-1f3fc-1f91d-1f468-1f3fb": "1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb",
    "1f468-1f3fc-1f91d-1f468-1f3fd": "1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd",
    "1f468-1f3fc-1f91d-1f468-1f3fe": "1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe",
    "1f468-1f3fc-1f91d-1f468-1f3ff": "1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff",
    "1f468-1f3fd-1f91d-1f468-1f3fb": "1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb",
    "1f468-1f3fd-1f91d-1f468-1f3fc": "1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc",
    "1f468-1f3fd-1f91d-1f468-1f3fe": "1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe",
    "1f468-1f3fd-1f91d-1f468-1f3ff": "1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff",
    "1f468-1f3fe-1f91d-1f468-1f3fb": "1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb",
    "1f468-1f3fe-1f91d-1f468-1f3fc": "1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc",
    "1f468-1f3fe-1f91d-1f468-1f3fd": "1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd",
    "1f468-1f3fe-1f91d-1f468-1f3ff": "1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff",
    "1f468-1f3ff-1f91d-1f468-1f3fb": "1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb",
    "1f468-1f3ff-1f91d-1f468-1f3fc": "1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc",
    "1f468-1f3ff-1f91d-1f468-1f3fd": "1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd",
    "1f468-1f3ff-1f91d-1f468-1f3fe": "1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe",
    "1f9d1-1f3fb-2764-1f9d1-1f3fc": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fc",
    "1f9d1-1f3fb-2764-1f9d1-1f3fd": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fd",
    "1f9d1-1f3fb-2764-1f9d1-1f3fe": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fe",
    "1f9d1-1f3fb-2764-1f9d1-1f3ff": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3ff",
    "1f9d1-1f3fc-2764-1f9d1-1f3fb": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fb",
    "1f9d1-1f3fc-2764-1f9d1-1f3fd": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fd",
    "1f9d1-1f3fc-2764-1f9d1-1f3fe": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fe",
    "1f9d1-1f3fc-2764-1f9d1-1f3ff": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3ff",
    "1f9d1-1f3fd-2764-1f9d1-1f3fb": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fb",
    "1f9d1-1f3fd-2764-1f9d1-1f3fc": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fc",
    "1f9d1-1f3fd-2764-1f9d1-1f3fe": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fe",
    "1f9d1-1f3fd-2764-1f9d1-1f3ff": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3ff",
    "1f9d1-1f3fe-2764-1f9d1-1f3fb": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fb",
    "1f9d1-1f3fe-2764-1f9d1-1f3fc": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fc",
    "1f9d1-1f3fe-2764-1f9d1-1f3fd": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fd",
    "1f9d1-1f3fe-2764-1f9d1-1f3ff": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3ff",
    "1f9d1-1f3ff-2764-1f9d1-1f3fb": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fb",
    "1f9d1-1f3ff-2764-1f9d1-1f3fc": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fc",
    "1f9d1-1f3ff-2764-1f9d1-1f3fd": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fd",
    "1f9d1-1f3ff-2764-1f9d1-1f3fe": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fe",
    "1f469-2764-1f468": "1f469-200d-2764-fe0f-200d-1f468",
    "1f469-1f3fb-2764-1f468-1f3fb": "1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f469-1f3fb-2764-1f468-1f3fc": "1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f469-1f3fb-2764-1f468-1f3fd": "1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f469-1f3fb-2764-1f468-1f3fe": "1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f469-1f3fb-2764-1f468-1f3ff": "1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f469-1f3fc-2764-1f468-1f3fb": "1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f469-1f3fc-2764-1f468-1f3fc": "1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f469-1f3fc-2764-1f468-1f3fd": "1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f469-1f3fc-2764-1f468-1f3fe": "1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f469-1f3fc-2764-1f468-1f3ff": "1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f469-1f3fd-2764-1f468-1f3fb": "1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f469-1f3fd-2764-1f468-1f3fc": "1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f469-1f3fd-2764-1f468-1f3fd": "1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f469-1f3fd-2764-1f468-1f3fe": "1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f469-1f3fd-2764-1f468-1f3ff": "1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f469-1f3fe-2764-1f468-1f3fb": "1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f469-1f3fe-2764-1f468-1f3fc": "1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f469-1f3fe-2764-1f468-1f3fd": "1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f469-1f3fe-2764-1f468-1f3fe": "1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f469-1f3fe-2764-1f468-1f3ff": "1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f469-1f3ff-2764-1f468-1f3fb": "1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f469-1f3ff-2764-1f468-1f3fc": "1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f469-1f3ff-2764-1f468-1f3fd": "1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f469-1f3ff-2764-1f468-1f3fe": "1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f469-1f3ff-2764-1f468-1f3ff": "1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f469-2764-1f469": "1f469-200d-2764-fe0f-200d-1f469",
    "1f469-1f3fb-2764-1f469-1f3fb": "1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fb",
    "1f469-1f3fb-2764-1f469-1f3fc": "1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fc",
    "1f469-1f3fb-2764-1f469-1f3fd": "1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fd",
    "1f469-1f3fb-2764-1f469-1f3fe": "1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fe",
    "1f469-1f3fb-2764-1f469-1f3ff": "1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3ff",
    "1f469-1f3fc-2764-1f469-1f3fb": "1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fb",
    "1f469-1f3fc-2764-1f469-1f3fc": "1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fc",
    "1f469-1f3fc-2764-1f469-1f3fd": "1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fd",
    "1f469-1f3fc-2764-1f469-1f3fe": "1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fe",
    "1f469-1f3fc-2764-1f469-1f3ff": "1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3ff",
    "1f469-1f3fd-2764-1f469-1f3fb": "1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fb",
    "1f469-1f3fd-2764-1f469-1f3fc": "1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fc",
    "1f469-1f3fd-2764-1f469-1f3fd": "1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fd",
    "1f469-1f3fd-2764-1f469-1f3fe": "1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fe",
    "1f469-1f3fd-2764-1f469-1f3ff": "1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3ff",
    "1f469-1f3fe-2764-1f469-1f3fb": "1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fb",
    "1f469-1f3fe-2764-1f469-1f3fc": "1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fc",
    "1f469-1f3fe-2764-1f469-1f3fd": "1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fd",
    "1f469-1f3fe-2764-1f469-1f3fe": "1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fe",
    "1f469-1f3fe-2764-1f469-1f3ff": "1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3ff",
    "1f469-1f3ff-2764-1f469-1f3fb": "1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fb",
    "1f469-1f3ff-2764-1f469-1f3fc": "1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fc",
    "1f469-1f3ff-2764-1f469-1f3fd": "1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fd",
    "1f469-1f3ff-2764-1f469-1f3fe": "1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fe",
    "1f469-1f3ff-2764-1f469-1f3ff": "1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3ff",
    "1f468-2764-1f468": "1f468-200d-2764-fe0f-200d-1f468",
    "1f468-1f3fb-2764-1f468-1f3fb": "1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f468-1f3fb-2764-1f468-1f3fc": "1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f468-1f3fb-2764-1f468-1f3fd": "1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f468-1f3fb-2764-1f468-1f3fe": "1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f468-1f3fb-2764-1f468-1f3ff": "1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f468-1f3fc-2764-1f468-1f3fb": "1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f468-1f3fc-2764-1f468-1f3fc": "1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f468-1f3fc-2764-1f468-1f3fd": "1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f468-1f3fc-2764-1f468-1f3fe": "1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f468-1f3fc-2764-1f468-1f3ff": "1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f468-1f3fd-2764-1f468-1f3fb": "1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f468-1f3fd-2764-1f468-1f3fc": "1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f468-1f3fd-2764-1f468-1f3fd": "1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f468-1f3fd-2764-1f468-1f3fe": "1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f468-1f3fd-2764-1f468-1f3ff": "1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f468-1f3fe-2764-1f468-1f3fb": "1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f468-1f3fe-2764-1f468-1f3fc": "1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f468-1f3fe-2764-1f468-1f3fd": "1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f468-1f3fe-2764-1f468-1f3fe": "1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f468-1f3fe-2764-1f468-1f3ff": "1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f468-1f3ff-2764-1f468-1f3fb": "1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb",
    "1f468-1f3ff-2764-1f468-1f3fc": "1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc",
    "1f468-1f3ff-2764-1f468-1f3fd": "1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd",
    "1f468-1f3ff-2764-1f468-1f3fe": "1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe",
    "1f468-1f3ff-2764-1f468-1f3ff": "1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff",
    "1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fc": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc",
    "1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fd": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd",
    "1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fe": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe",
    "1f9d1-1f3fb-2764-1f48b-1f9d1-1f3ff": "1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff",
    "1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fb": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb",
    "1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fd": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd",
    "1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fe": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe",
    "1f9d1-1f3fc-2764-1f48b-1f9d1-1f3ff": "1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff",
    "1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fb": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb",
    "1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fc": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc",
    "1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fe": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe",
    "1f9d1-1f3fd-2764-1f48b-1f9d1-1f3ff": "1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff",
    "1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fb": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb",
    "1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fc": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc",
    "1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fd": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd",
    "1f9d1-1f3fe-2764-1f48b-1f9d1-1f3ff": "1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff",
    "1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fb": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb",
    "1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fc": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc",
    "1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fd": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd",
    "1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fe": "1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe",
    "1f469-2764-1f48b-1f468": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f468",
    "1f469-1f3fb-2764-1f48b-1f468-1f3fb": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f469-1f3fb-2764-1f48b-1f468-1f3fc": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f469-1f3fb-2764-1f48b-1f468-1f3fd": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f469-1f3fb-2764-1f48b-1f468-1f3fe": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f469-1f3fb-2764-1f48b-1f468-1f3ff": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f469-1f3fc-2764-1f48b-1f468-1f3fb": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f469-1f3fc-2764-1f48b-1f468-1f3fc": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f469-1f3fc-2764-1f48b-1f468-1f3fd": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f469-1f3fc-2764-1f48b-1f468-1f3fe": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f469-1f3fc-2764-1f48b-1f468-1f3ff": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f469-1f3fd-2764-1f48b-1f468-1f3fb": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f469-1f3fd-2764-1f48b-1f468-1f3fc": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f469-1f3fd-2764-1f48b-1f468-1f3fd": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f469-1f3fd-2764-1f48b-1f468-1f3fe": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f469-1f3fd-2764-1f48b-1f468-1f3ff": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f469-1f3fe-2764-1f48b-1f468-1f3fb": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f469-1f3fe-2764-1f48b-1f468-1f3fc": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f469-1f3fe-2764-1f48b-1f468-1f3fd": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f469-1f3fe-2764-1f48b-1f468-1f3fe": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f469-1f3fe-2764-1f48b-1f468-1f3ff": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f469-1f3ff-2764-1f48b-1f468-1f3fb": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f469-1f3ff-2764-1f48b-1f468-1f3fc": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f469-1f3ff-2764-1f48b-1f468-1f3fd": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f469-1f3ff-2764-1f48b-1f468-1f3fe": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f469-1f3ff-2764-1f48b-1f468-1f3ff": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f469-2764-1f48b-1f469": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f469",
    "1f469-1f3fb-2764-1f48b-1f469-1f3fb": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb",
    "1f469-1f3fb-2764-1f48b-1f469-1f3fc": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc",
    "1f469-1f3fb-2764-1f48b-1f469-1f3fd": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd",
    "1f469-1f3fb-2764-1f48b-1f469-1f3fe": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe",
    "1f469-1f3fb-2764-1f48b-1f469-1f3ff": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff",
    "1f469-1f3fc-2764-1f48b-1f469-1f3fb": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb",
    "1f469-1f3fc-2764-1f48b-1f469-1f3fc": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc",
    "1f469-1f3fc-2764-1f48b-1f469-1f3fd": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd",
    "1f469-1f3fc-2764-1f48b-1f469-1f3fe": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe",
    "1f469-1f3fc-2764-1f48b-1f469-1f3ff": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff",
    "1f469-1f3fd-2764-1f48b-1f469-1f3fb": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb",
    "1f469-1f3fd-2764-1f48b-1f469-1f3fc": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc",
    "1f469-1f3fd-2764-1f48b-1f469-1f3fd": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd",
    "1f469-1f3fd-2764-1f48b-1f469-1f3fe": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe",
    "1f469-1f3fd-2764-1f48b-1f469-1f3ff": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff",
    "1f469-1f3fe-2764-1f48b-1f469-1f3fb": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb",
    "1f469-1f3fe-2764-1f48b-1f469-1f3fc": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc",
    "1f469-1f3fe-2764-1f48b-1f469-1f3fd": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd",
    "1f469-1f3fe-2764-1f48b-1f469-1f3fe": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe",
    "1f469-1f3fe-2764-1f48b-1f469-1f3ff": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff",
    "1f469-1f3ff-2764-1f48b-1f469-1f3fb": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb",
    "1f469-1f3ff-2764-1f48b-1f469-1f3fc": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc",
    "1f469-1f3ff-2764-1f48b-1f469-1f3fd": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd",
    "1f469-1f3ff-2764-1f48b-1f469-1f3fe": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe",
    "1f469-1f3ff-2764-1f48b-1f469-1f3ff": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff",
    "1f468-2764-1f48b-1f468": "1f468-200d-2764-fe0f-200d-1f48b-200d-1f468",
    "1f468-1f3fb-2764-1f48b-1f468-1f3fb": "1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f468-1f3fb-2764-1f48b-1f468-1f3fc": "1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f468-1f3fb-2764-1f48b-1f468-1f3fd": "1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f468-1f3fb-2764-1f48b-1f468-1f3fe": "1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f468-1f3fb-2764-1f48b-1f468-1f3ff": "1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f468-1f3fc-2764-1f48b-1f468-1f3fb": "1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f468-1f3fc-2764-1f48b-1f468-1f3fc": "1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f468-1f3fc-2764-1f48b-1f468-1f3fd": "1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f468-1f3fc-2764-1f48b-1f468-1f3fe": "1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f468-1f3fc-2764-1f48b-1f468-1f3ff": "1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f468-1f3fd-2764-1f48b-1f468-1f3fb": "1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f468-1f3fd-2764-1f48b-1f468-1f3fc": "1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f468-1f3fd-2764-1f48b-1f468-1f3fd": "1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f468-1f3fd-2764-1f48b-1f468-1f3fe": "1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f468-1f3fd-2764-1f48b-1f468-1f3ff": "1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f468-1f3fe-2764-1f48b-1f468-1f3fb": "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f468-1f3fe-2764-1f48b-1f468-1f3fc": "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f468-1f3fe-2764-1f48b-1f468-1f3fd": "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f468-1f3fe-2764-1f48b-1f468-1f3fe": "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f468-1f3fe-2764-1f48b-1f468-1f3ff": "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f468-1f3ff-2764-1f48b-1f468-1f3fb": "1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
    "1f468-1f3ff-2764-1f48b-1f468-1f3fc": "1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
    "1f468-1f3ff-2764-1f48b-1f468-1f3fd": "1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
    "1f468-1f3ff-2764-1f48b-1f468-1f3fe": "1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
    "1f468-1f3ff-2764-1f48b-1f468-1f3ff": "1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
    "1f468-1f469-1f466": "1f468-200d-1f469-200d-1f466",
    "1f468-1f469-1f467": "1f468-200d-1f469-200d-1f467",
    "1f468-1f469-1f467-1f466": "1f468-200d-1f469-200d-1f467-200d-1f466",
    "1f468-1f469-1f466-1f466": "1f468-200d-1f469-200d-1f466-200d-1f466",
    "1f468-1f469-1f467-1f467": "1f468-200d-1f469-200d-1f467-200d-1f467",
    "1f469-1f469-1f466": "1f469-200d-1f469-200d-1f466",
    "1f469-1f469-1f467": "1f469-200d-1f469-200d-1f467",
    "1f469-1f469-1f467-1f466": "1f469-200d-1f469-200d-1f467-200d-1f466",
    "1f469-1f469-1f466-1f466": "1f469-200d-1f469-200d-1f466-200d-1f466",
    "1f469-1f469-1f467-1f467": "1f469-200d-1f469-200d-1f467-200d-1f467",
    "1f468-1f468-1f466": "1f468-200d-1f468-200d-1f466",
    "1f468-1f468-1f467": "1f468-200d-1f468-200d-1f467",
    "1f468-1f468-1f467-1f466": "1f468-200d-1f468-200d-1f467-200d-1f466",
    "1f468-1f468-1f466-1f466": "1f468-200d-1f468-200d-1f466-200d-1f466",
    "1f468-1f468-1f467-1f467": "1f468-200d-1f468-200d-1f467-200d-1f467",
    "1f469-1f466": "1f469-200d-1f466",
    "1f469-1f467": "1f469-200d-1f467",
    "1f469-1f467-1f466": "1f469-200d-1f467-200d-1f466",
    "1f469-1f466-1f466": "1f469-200d-1f466-200d-1f466",
    "1f469-1f467-1f467": "1f469-200d-1f467-200d-1f467",
    "1f468-1f466": "1f468-200d-1f466",
    "1f468-1f467": "1f468-200d-1f467",
    "1f468-1f467-1f466": "1f468-200d-1f467-200d-1f466",
    "1f468-1f466-1f466": "1f468-200d-1f466-200d-1f466",
    "1f468-1f467-1f467": "1f468-200d-1f467-200d-1f467",
    "26d1": "26d1-fe0f",
    "1f576": "1f576-fe0f",
    "1f43b-2744": "1f43b-200d-2744-fe0f",
    "1f426-2b1b": "1f426-200d-2b1b",
    "1f577": "1f577-fe0f",
    "1f578": "1f578-fe0f",
    "1f415-1f9ba": "1f415-200d-1f9ba",
    "1f408-2b1b": "1f408-200d-2b1b",
    "1f54a": "1f54a-fe0f",
    "1f43f": "1f43f-fe0f",
    "1f32a": "1f32a-fe0f",
    "1f324": "1f324-fe0f",
    "1f325": "1f325-fe0f",
    "1f326": "1f326-fe0f",
    "1f327": "1f327-fe0f",
    "26c8": "26c8-fe0f",
    "1f329": "1f329-fe0f",
    "1f328": "1f328-fe0f",
    "1f32c": "1f32c-fe0f",
    "1f32b": "1f32b-fe0f",
    "1f336": "1f336-fe0f",
    "1f37d": "1f37d-fe0f",
    "26f8": "26f8-fe0f",
    "26f7": "26f7-fe0f",
    "1f3cb": "1f3cb-fe0f",
    "1f3cb-2640": "1f3cb-fe0f-200d-2640-fe0f",
    "1f3cb-1f3fb-2640": "1f3cb-1f3fb-200d-2640-fe0f",
    "1f3cb-1f3fc-2640": "1f3cb-1f3fc-200d-2640-fe0f",
    "1f3cb-1f3fd-2640": "1f3cb-1f3fd-200d-2640-fe0f",
    "1f3cb-1f3fe-2640": "1f3cb-1f3fe-200d-2640-fe0f",
    "1f3cb-1f3ff-2640": "1f3cb-1f3ff-200d-2640-fe0f",
    "1f3cb-2642": "1f3cb-fe0f-200d-2642-fe0f",
    "1f3cb-1f3fb-2642": "1f3cb-1f3fb-200d-2642-fe0f",
    "1f3cb-1f3fc-2642": "1f3cb-1f3fc-200d-2642-fe0f",
    "1f3cb-1f3fd-2642": "1f3cb-1f3fd-200d-2642-fe0f",
    "1f3cb-1f3fe-2642": "1f3cb-1f3fe-200d-2642-fe0f",
    "1f3cb-1f3ff-2642": "1f3cb-1f3ff-200d-2642-fe0f",
    "1f93c-2640": "1f93c-200d-2640-fe0f",
    "1f93c-2642": "1f93c-200d-2642-fe0f",
    "1f938-2640": "1f938-200d-2640-fe0f",
    "1f938-1f3fb-2640": "1f938-1f3fb-200d-2640-fe0f",
    "1f938-1f3fc-2640": "1f938-1f3fc-200d-2640-fe0f",
    "1f938-1f3fd-2640": "1f938-1f3fd-200d-2640-fe0f",
    "1f938-1f3fe-2640": "1f938-1f3fe-200d-2640-fe0f",
    "1f938-1f3ff-2640": "1f938-1f3ff-200d-2640-fe0f",
    "1f938-2642": "1f938-200d-2642-fe0f",
    "1f938-1f3fb-2642": "1f938-1f3fb-200d-2642-fe0f",
    "1f938-1f3fc-2642": "1f938-1f3fc-200d-2642-fe0f",
    "1f938-1f3fd-2642": "1f938-1f3fd-200d-2642-fe0f",
    "1f938-1f3fe-2642": "1f938-1f3fe-200d-2642-fe0f",
    "1f938-1f3ff-2642": "1f938-1f3ff-200d-2642-fe0f",
    "26f9": "26f9-fe0f",
    "26f9-2640": "26f9-fe0f-200d-2640-fe0f",
    "26f9-1f3fb-2640": "26f9-1f3fb-200d-2640-fe0f",
    "26f9-1f3fc-2640": "26f9-1f3fc-200d-2640-fe0f",
    "26f9-1f3fd-2640": "26f9-1f3fd-200d-2640-fe0f",
    "26f9-1f3fe-2640": "26f9-1f3fe-200d-2640-fe0f",
    "26f9-1f3ff-2640": "26f9-1f3ff-200d-2640-fe0f",
    "26f9-2642": "26f9-fe0f-200d-2642-fe0f",
    "26f9-1f3fb-2642": "26f9-1f3fb-200d-2642-fe0f",
    "26f9-1f3fc-2642": "26f9-1f3fc-200d-2642-fe0f",
    "26f9-1f3fd-2642": "26f9-1f3fd-200d-2642-fe0f",
    "26f9-1f3fe-2642": "26f9-1f3fe-200d-2642-fe0f",
    "26f9-1f3ff-2642": "26f9-1f3ff-200d-2642-fe0f",
    "1f93e-2640": "1f93e-200d-2640-fe0f",
    "1f93e-1f3fb-2640": "1f93e-1f3fb-200d-2640-fe0f",
    "1f93e-1f3fc-2640": "1f93e-1f3fc-200d-2640-fe0f",
    "1f93e-1f3fd-2640": "1f93e-1f3fd-200d-2640-fe0f",
    "1f93e-1f3fe-2640": "1f93e-1f3fe-200d-2640-fe0f",
    "1f93e-1f3ff-2640": "1f93e-1f3ff-200d-2640-fe0f",
    "1f93e-2642": "1f93e-200d-2642-fe0f",
    "1f93e-1f3fb-2642": "1f93e-1f3fb-200d-2642-fe0f",
    "1f93e-1f3fc-2642": "1f93e-1f3fc-200d-2642-fe0f",
    "1f93e-1f3fd-2642": "1f93e-1f3fd-200d-2642-fe0f",
    "1f93e-1f3fe-2642": "1f93e-1f3fe-200d-2642-fe0f",
    "1f93e-1f3ff-2642": "1f93e-1f3ff-200d-2642-fe0f",
    "1f3cc": "1f3cc-fe0f",
    "1f3cc-2640": "1f3cc-fe0f-200d-2640-fe0f",
    "1f3cc-1f3fb-2640": "1f3cc-1f3fb-200d-2640-fe0f",
    "1f3cc-1f3fc-2640": "1f3cc-1f3fc-200d-2640-fe0f",
    "1f3cc-1f3fd-2640": "1f3cc-1f3fd-200d-2640-fe0f",
    "1f3cc-1f3fe-2640": "1f3cc-1f3fe-200d-2640-fe0f",
    "1f3cc-1f3ff-2640": "1f3cc-1f3ff-200d-2640-fe0f",
    "1f3cc-2642": "1f3cc-fe0f-200d-2642-fe0f",
    "1f3cc-1f3fb-2642": "1f3cc-1f3fb-200d-2642-fe0f",
    "1f3cc-1f3fc-2642": "1f3cc-1f3fc-200d-2642-fe0f",
    "1f3cc-1f3fd-2642": "1f3cc-1f3fd-200d-2642-fe0f",
    "1f3cc-1f3fe-2642": "1f3cc-1f3fe-200d-2642-fe0f",
    "1f3cc-1f3ff-2642": "1f3cc-1f3ff-200d-2642-fe0f",
    "1f9d8-2640": "1f9d8-200d-2640-fe0f",
    "1f9d8-1f3fb-2640": "1f9d8-1f3fb-200d-2640-fe0f",
    "1f9d8-1f3fc-2640": "1f9d8-1f3fc-200d-2640-fe0f",
    "1f9d8-1f3fd-2640": "1f9d8-1f3fd-200d-2640-fe0f",
    "1f9d8-1f3fe-2640": "1f9d8-1f3fe-200d-2640-fe0f",
    "1f9d8-1f3ff-2640": "1f9d8-1f3ff-200d-2640-fe0f",
    "1f9d8-2642": "1f9d8-200d-2642-fe0f",
    "1f9d8-1f3fb-2642": "1f9d8-1f3fb-200d-2642-fe0f",
    "1f9d8-1f3fc-2642": "1f9d8-1f3fc-200d-2642-fe0f",
    "1f9d8-1f3fd-2642": "1f9d8-1f3fd-200d-2642-fe0f",
    "1f9d8-1f3fe-2642": "1f9d8-1f3fe-200d-2642-fe0f",
    "1f9d8-1f3ff-2642": "1f9d8-1f3ff-200d-2642-fe0f",
    "1f3c4-2640": "1f3c4-200d-2640-fe0f",
    "1f3c4-1f3fb-2640": "1f3c4-1f3fb-200d-2640-fe0f",
    "1f3c4-1f3fc-2640": "1f3c4-1f3fc-200d-2640-fe0f",
    "1f3c4-1f3fd-2640": "1f3c4-1f3fd-200d-2640-fe0f",
    "1f3c4-1f3fe-2640": "1f3c4-1f3fe-200d-2640-fe0f",
    "1f3c4-1f3ff-2640": "1f3c4-1f3ff-200d-2640-fe0f",
    "1f3c4-2642": "1f3c4-200d-2642-fe0f",
    "1f3c4-1f3fb-2642": "1f3c4-1f3fb-200d-2642-fe0f",
    "1f3c4-1f3fc-2642": "1f3c4-1f3fc-200d-2642-fe0f",
    "1f3c4-1f3fd-2642": "1f3c4-1f3fd-200d-2642-fe0f",
    "1f3c4-1f3fe-2642": "1f3c4-1f3fe-200d-2642-fe0f",
    "1f3c4-1f3ff-2642": "1f3c4-1f3ff-200d-2642-fe0f",
    "1f3ca-2640": "1f3ca-200d-2640-fe0f",
    "1f3ca-1f3fb-2640": "1f3ca-1f3fb-200d-2640-fe0f",
    "1f3ca-1f3fc-2640": "1f3ca-1f3fc-200d-2640-fe0f",
    "1f3ca-1f3fd-2640": "1f3ca-1f3fd-200d-2640-fe0f",
    "1f3ca-1f3fe-2640": "1f3ca-1f3fe-200d-2640-fe0f",
    "1f3ca-1f3ff-2640": "1f3ca-1f3ff-200d-2640-fe0f",
    "1f3ca-2642": "1f3ca-200d-2642-fe0f",
    "1f3ca-1f3fb-2642": "1f3ca-1f3fb-200d-2642-fe0f",
    "1f3ca-1f3fc-2642": "1f3ca-1f3fc-200d-2642-fe0f",
    "1f3ca-1f3fd-2642": "1f3ca-1f3fd-200d-2642-fe0f",
    "1f3ca-1f3fe-2642": "1f3ca-1f3fe-200d-2642-fe0f",
    "1f3ca-1f3ff-2642": "1f3ca-1f3ff-200d-2642-fe0f",
    "1f93d-2640": "1f93d-200d-2640-fe0f",
    "1f93d-1f3fb-2640": "1f93d-1f3fb-200d-2640-fe0f",
    "1f93d-1f3fc-2640": "1f93d-1f3fc-200d-2640-fe0f",
    "1f93d-1f3fd-2640": "1f93d-1f3fd-200d-2640-fe0f",
    "1f93d-1f3fe-2640": "1f93d-1f3fe-200d-2640-fe0f",
    "1f93d-1f3ff-2640": "1f93d-1f3ff-200d-2640-fe0f",
    "1f93d-2642": "1f93d-200d-2642-fe0f",
    "1f93d-1f3fb-2642": "1f93d-1f3fb-200d-2642-fe0f",
    "1f93d-1f3fc-2642": "1f93d-1f3fc-200d-2642-fe0f",
    "1f93d-1f3fd-2642": "1f93d-1f3fd-200d-2642-fe0f",
    "1f93d-1f3fe-2642": "1f93d-1f3fe-200d-2642-fe0f",
    "1f93d-1f3ff-2642": "1f93d-1f3ff-200d-2642-fe0f",
    "1f6a3-2640": "1f6a3-200d-2640-fe0f",
    "1f6a3-1f3fb-2640": "1f6a3-1f3fb-200d-2640-fe0f",
    "1f6a3-1f3fc-2640": "1f6a3-1f3fc-200d-2640-fe0f",
    "1f6a3-1f3fd-2640": "1f6a3-1f3fd-200d-2640-fe0f",
    "1f6a3-1f3fe-2640": "1f6a3-1f3fe-200d-2640-fe0f",
    "1f6a3-1f3ff-2640": "1f6a3-1f3ff-200d-2640-fe0f",
    "1f6a3-2642": "1f6a3-200d-2642-fe0f",
    "1f6a3-1f3fb-2642": "1f6a3-1f3fb-200d-2642-fe0f",
    "1f6a3-1f3fc-2642": "1f6a3-1f3fc-200d-2642-fe0f",
    "1f6a3-1f3fd-2642": "1f6a3-1f3fd-200d-2642-fe0f",
    "1f6a3-1f3fe-2642": "1f6a3-1f3fe-200d-2642-fe0f",
    "1f6a3-1f3ff-2642": "1f6a3-1f3ff-200d-2642-fe0f",
    "1f9d7-2640": "1f9d7-200d-2640-fe0f",
    "1f9d7-1f3fb-2640": "1f9d7-1f3fb-200d-2640-fe0f",
    "1f9d7-1f3fc-2640": "1f9d7-1f3fc-200d-2640-fe0f",
    "1f9d7-1f3fd-2640": "1f9d7-1f3fd-200d-2640-fe0f",
    "1f9d7-1f3fe-2640": "1f9d7-1f3fe-200d-2640-fe0f",
    "1f9d7-1f3ff-2640": "1f9d7-1f3ff-200d-2640-fe0f",
    "1f9d7-2642": "1f9d7-200d-2642-fe0f",
    "1f9d7-1f3fb-2642": "1f9d7-1f3fb-200d-2642-fe0f",
    "1f9d7-1f3fc-2642": "1f9d7-1f3fc-200d-2642-fe0f",
    "1f9d7-1f3fd-2642": "1f9d7-1f3fd-200d-2642-fe0f",
    "1f9d7-1f3fe-2642": "1f9d7-1f3fe-200d-2642-fe0f",
    "1f9d7-1f3ff-2642": "1f9d7-1f3ff-200d-2642-fe0f",
    "1f6b5-2640": "1f6b5-200d-2640-fe0f",
    "1f6b5-1f3fb-2640": "1f6b5-1f3fb-200d-2640-fe0f",
    "1f6b5-1f3fc-2640": "1f6b5-1f3fc-200d-2640-fe0f",
    "1f6b5-1f3fd-2640": "1f6b5-1f3fd-200d-2640-fe0f",
    "1f6b5-1f3fe-2640": "1f6b5-1f3fe-200d-2640-fe0f",
    "1f6b5-1f3ff-2640": "1f6b5-1f3ff-200d-2640-fe0f",
    "1f6b5-2642": "1f6b5-200d-2642-fe0f",
    "1f6b5-1f3fb-2642": "1f6b5-1f3fb-200d-2642-fe0f",
    "1f6b5-1f3fc-2642": "1f6b5-1f3fc-200d-2642-fe0f",
    "1f6b5-1f3fd-2642": "1f6b5-1f3fd-200d-2642-fe0f",
    "1f6b5-1f3fe-2642": "1f6b5-1f3fe-200d-2642-fe0f",
    "1f6b5-1f3ff-2642": "1f6b5-1f3ff-200d-2642-fe0f",
    "1f6b4-2640": "1f6b4-200d-2640-fe0f",
    "1f6b4-1f3fb-2640": "1f6b4-1f3fb-200d-2640-fe0f",
    "1f6b4-1f3fc-2640": "1f6b4-1f3fc-200d-2640-fe0f",
    "1f6b4-1f3fd-2640": "1f6b4-1f3fd-200d-2640-fe0f",
    "1f6b4-1f3fe-2640": "1f6b4-1f3fe-200d-2640-fe0f",
    "1f6b4-1f3ff-2640": "1f6b4-1f3ff-200d-2640-fe0f",
    "1f6b4-2642": "1f6b4-200d-2642-fe0f",
    "1f6b4-1f3fb-2642": "1f6b4-1f3fb-200d-2642-fe0f",
    "1f6b4-1f3fc-2642": "1f6b4-1f3fc-200d-2642-fe0f",
    "1f6b4-1f3fd-2642": "1f6b4-1f3fd-200d-2642-fe0f",
    "1f6b4-1f3fe-2642": "1f6b4-1f3fe-200d-2642-fe0f",
    "1f6b4-1f3ff-2642": "1f6b4-1f3ff-200d-2642-fe0f",
    "1f396": "1f396-fe0f",
    "1f3f5": "1f3f5-fe0f",
    "1f397": "1f397-fe0f",
    "1f39f": "1f39f-fe0f",
    "1f939-2640": "1f939-200d-2640-fe0f",
    "1f939-1f3fb-2640": "1f939-1f3fb-200d-2640-fe0f",
    "1f939-1f3fc-2640": "1f939-1f3fc-200d-2640-fe0f",
    "1f939-1f3fd-2640": "1f939-1f3fd-200d-2640-fe0f",
    "1f939-1f3fe-2640": "1f939-1f3fe-200d-2640-fe0f",
    "1f939-1f3ff-2640": "1f939-1f3ff-200d-2640-fe0f",
    "1f939-2642": "1f939-200d-2642-fe0f",
    "1f939-1f3fb-2642": "1f939-1f3fb-200d-2642-fe0f",
    "1f939-1f3fc-2642": "1f939-1f3fc-200d-2642-fe0f",
    "1f939-1f3fd-2642": "1f939-1f3fd-200d-2642-fe0f",
    "1f939-1f3fe-2642": "1f939-1f3fe-200d-2642-fe0f",
    "1f939-1f3ff-2642": "1f939-1f3ff-200d-2642-fe0f",
    "265f": "265f-fe0f",
    "1f3ce": "1f3ce-fe0f",
    "1f3cd": "1f3cd-fe0f",
    "1f6e9": "1f6e9-fe0f",
    "1f6f0": "1f6f0-fe0f",
    "1f6e5": "1f6e5-fe0f",
    "1f6f3": "1f6f3-fe0f",
    "26f4": "26f4-fe0f",
    "1f5fa": "1f5fa-fe0f",
    "1f3df": "1f3df-fe0f",
    "26f1": "26f1-fe0f",
    "1f3d6": "1f3d6-fe0f",
    "1f3dd": "1f3dd-fe0f",
    "1f3dc": "1f3dc-fe0f",
    "26f0": "26f0-fe0f",
    "1f3d4": "1f3d4-fe0f",
    "1f3d5": "1f3d5-fe0f",
    "1f3d8": "1f3d8-fe0f",
    "1f3da": "1f3da-fe0f",
    "1f3d7": "1f3d7-fe0f",
    "1f3db": "1f3db-fe0f",
    "26e9": "26e9-fe0f",
    "1f6e4": "1f6e4-fe0f",
    "1f6e3": "1f6e3-fe0f",
    "1f3de": "1f3de-fe0f",
    "1f3d9": "1f3d9-fe0f",
    "1f5a5": "1f5a5-fe0f",
    "1f5a8": "1f5a8-fe0f",
    "1f5b1": "1f5b1-fe0f",
    "1f5b2": "1f5b2-fe0f",
    "1f579": "1f579-fe0f",
    "1f5dc": "1f5dc-fe0f",
    "1f4fd": "1f4fd-fe0f",
    "1f39e": "1f39e-fe0f",
    "260e": "260e-fe0f",
    "1f399": "1f399-fe0f",
    "1f39a": "1f39a-fe0f",
    "1f39b": "1f39b-fe0f",
    "23f1": "23f1-fe0f",
    "23f2": "23f2-fe0f",
    "1f570": "1f570-fe0f",
    "1f56f": "1f56f-fe0f",
    "1f6e2": "1f6e2-fe0f",
    "1f6e0": "1f6e0-fe0f",
    "26cf": "26cf-fe0f",
    "26d3": "26d3-fe0f",
    "1f5e1": "1f5e1-fe0f",
    "1f6e1": "1f6e1-fe0f",
    "26b0": "26b0-fe0f",
    "26b1": "26b1-fe0f",
    "1f573": "1f573-fe0f",
    "1f321": "1f321-fe0f",
    "1f6ce": "1f6ce-fe0f",
    "1f5dd": "1f5dd-fe0f",
    "1f6cb": "1f6cb-fe0f",
    "1f6cf": "1f6cf-fe0f",
    "1f5bc": "1f5bc-fe0f",
    "1f6cd": "1f6cd-fe0f",
    "1f3f7": "1f3f7-fe0f",
    "1f5d2": "1f5d2-fe0f",
    "1f5d3": "1f5d3-fe0f",
    "1f5d1": "1f5d1-fe0f",
    "1f5c3": "1f5c3-fe0f",
    "1f5f3": "1f5f3-fe0f",
    "1f5c4": "1f5c4-fe0f",
    "1f5c2": "1f5c2-fe0f",
    "1f5de": "1f5de-fe0f",
    "1f587": "1f587-fe0f",
    "1f58a": "1f58a-fe0f",
    "1f58b": "1f58b-fe0f",
    "1f58c": "1f58c-fe0f",
    "1f58d": "1f58d-fe0f",
    "270f": "270f-fe0f",
    "2764-1fa79": "2764-fe0f-200d-1fa79",
    "2764-1f525": "2764-fe0f-200d-1f525",
    "262e": "262e-fe0f",
    "271d": "271d-fe0f",
    "262a": "262a-fe0f",
    "1f549": "1f549-fe0f",
    "262f": "262f-fe0f",
    "269b": "269b-fe0f",
    "1f237": "1f237-fe0f",
    "1f170": "1f170-fe0f",
    "1f171": "1f171-fe0f",
    "1f17e": "1f17e-fe0f",
    "203c": "203c-fe0f",
    "303d": "303d-fe0f",
    "26a0": "26a0-fe0f",
    "269c": "269c-fe0f",
    "267b": "267b-fe0f",
    "24c2": "24c2-fe0f",
    "1f17f": "1f17f-fe0f",
    "1f202": "1f202-fe0f",
    "0030-20e3": "0030-fe0f-20e3",
    "0031-20e3": "0031-fe0f-20e3",
    "0032-20e3": "0032-fe0f-20e3",
    "0033-20e3": "0033-fe0f-20e3",
    "0034-20e3": "0034-fe0f-20e3",
    "0035-20e3": "0035-fe0f-20e3",
    "0036-20e3": "0036-fe0f-20e3",
    "0037-20e3": "0037-fe0f-20e3",
    "0038-20e3": "0038-fe0f-20e3",
    "0039-20e3": "0039-fe0f-20e3",
    "0023-20e3": "0023-fe0f-20e3",
    "002a-20e3": "002a-fe0f-20e3",
    "23cf": "23cf-fe0f",
    "25b6": "25b6-fe0f",
    "23f8": "23f8-fe0f",
    "23ef": "23ef-fe0f",
    "23f9": "23f9-fe0f",
    "23fa": "23fa-fe0f",
    "23ed": "23ed-fe0f",
    "23ee": "23ee-fe0f",
    "25c0": "25c0-fe0f",
    "27a1": "27a1-fe0f",
    "2b05": "2b05-fe0f",
    "2b06": "2b06-fe0f",
    "2b07": "2b07-fe0f",
    "21aa": "21aa-fe0f",
    "21a9": "21a9-fe0f",
    "267e": "267e-fe0f",
    "00a9": "00a9-fe0f",
    "00ae": "00ae-fe0f",
    "25aa": "25aa-fe0f",
    "25ab": "25ab-fe0f",
    "25fc": "25fc-fe0f",
    "25fb": "25fb-fe0f",
    "1f5e8": "1f5e8-fe0f",
    "1f441-1f5e8": "1f441-fe0f-200d-1f5e8-fe0f",
    "1f5ef": "1f5ef-fe0f",
    "0030": "0030-fe0f",
    "0031": "0031-fe0f",
    "0032": "0032-fe0f",
    "0033": "0033-fe0f",
    "0034": "0034-fe0f",
    "0035": "0035-fe0f",
    "0036": "0036-fe0f",
    "0037": "0037-fe0f",
    "0038": "0038-fe0f",
    "0039": "0039-fe0f",
    "0023": "0023-fe0f",
    "002a": "002a-fe0f",
    "1f3f3": "1f3f3-fe0f",
    "1f3f4-2620": "1f3f4-200d-2620-fe0f",
    "1f3f3-1f308": "1f3f3-fe0f-200d-1f308",
    "1f3f3-26a7": "1f3f3-fe0f-200d-26a7-fe0f"
};</string,>